import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./index.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SCHDULE from "../../../assets/img/overtime//meal.svg";

import plus from "../../../assets/img/plus.svg";
import Form from "react-bootstrap/Form";
import axios from "../../../axios";
import { API_CALL_URL_PRIVATE } from "../../../constants/compensate";
import SuccessModal from "../../../components/shared/SuccessModal";
import Chart from "react-apexcharts";
import { get, isEmpty } from "lodash";
import { ThemeContext } from "../../../providers/ThemeProvider";
import moment from "moment";
import {
  capitalizeAllLetters,
  formatNumberWithFraction,
} from "../../../utils/helper";
import { getTableRecordWithSorting } from "../../../services";
import DescIcon from "../../../assets/img/desc.png";
import AscIcon from "../../../assets/img/asc.png";
import Bi5 from "../../../assets/img/comment-blue.png";
import Approve from "../../../assets/img/done.png";
import UnApprove from "../../../assets/img/done1.png";
import Reject from "../../../assets/img/close1.png";
import UnReject from "../../../assets/img/close2.png";
import More from "../../../assets/img/ui.png";
import Less from "../../../assets/img/arrow-left.png";
import SHIFT_FORM from "../../../constants/shiftForm";
import { decryptedFunction, dataEnCrypt } from "../../../utils/helper";
import ContentLoader from "react-content-loader";
import BiArrowDown from "../../../assets/img/biarrowdown.png";
import BiArrowUp from "../../../assets/img/biarrowup.png";

const TargetLoader = ({ themeMode }) => (
  // <div style={{ width: 160, height: 140 }}>
  <ContentLoader
    viewBox="0 0 80 15"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="0" rx="0" ry="0" width="100" height="250" />
  </ContentLoader>
  // </div>
);

const TableLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 20"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="40" />
  </ContentLoader>
);

function InfoModal(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [commentData, setCommentData] = React.useState([]);
  const [showsucess, setShowsucess] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [sortType, setSortType] = useState("asc");
  const [sortName, setsortName] = useState("");
  const [uosTableData, setUosTableData] = useState([]);
  const [uosHeaderData, setUosHeaderData] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const showComment = props?.tableData?.showCommentButton;
  const [expandedComments, setExpandedComments] = useState({});
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const [themeMode, setTheme] = useContext(ThemeContext);

   useEffect(() => {
     if (props.show) {
       budgetDetail();
     }
   }, [props.show]);


    const budgetDetail = async () => {
      try {
        const paramData = {
          cost_center_number: props?.costCenterNumber?.value || null,
          payperiod_end_date: props?.endDate || null,
        };
        const encryptData = dataEnCrypt(paramData);
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/biweeklyReporting/costCenterScorecard/uosDetail`,
          { payloadData: encryptData }
        );
        const data = decryptedFunction(response?.data?.responseData);
        setUosTableData(data?.data?.tableData);
        setUosHeaderData(data?.data)
      } catch (error) {
        console.log(error);
      }
    };

    console.log("dop", uosTableData);
    

  const hoursTotal = () => {
    const totalSum = uosTableData?.reduce((accumulator, currentObject) => {
      return accumulator + currentObject?.total_ftes;
    }, 0);
    console.log("totalSum", totalSum);
    
    return totalSum;
  };
  const commentDetail = async (centerNum, date) => {
    try {
      const paramData = {
        cost_center_number: centerNum,
        when_posted: date,
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklySummary/addCommentData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setCommentData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  function convertDateFormat(dateStr) {
    const parts = dateStr.split("-"); // Split the date by '-'
    if (parts.length === 3) {
      const [day, month, year] = parts;
      const shortYear = year; // Get the last two digits of the year
      return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${shortYear}`;
    } else {
      return null; // Return null or handle invalid format as needed
    }
  }

  const sortingHandle = (key, bindname = null) => {
    const dataToSort = bindname
      ? get(uosTableData, bindname, [])
      : uosTableData;
    const sortdata = getTableRecordWithSorting(dataToSort, key, sortType);
    setSortType(sortType === "asc" ? "desc" : "asc");

    if (bindname) {
      setUosTableData({ ...uosTableData, [bindname]: sortdata });
    } else {
      setUosTableData(sortdata);
    }

    if (key === "hours") {
      if (props?.tableData?.fixed_or_variable === "fixed") {
        key = "paid_hours";
      } else {
        key = "worked_hours";
      }
    }
    activityLog(SHIFT_FORM.AGENCY_DAILY_MODAL[key] ?? "", sortType);

    setsortName(key);
  };

  const manageReview = async (_id, _manager_review) => {
    try {
      const paramData = {
        _id: _id,
        manager_review: _manager_review,
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/comment/managerReview`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setSuccessMessage(data.message);
      setShowsucess(true);
      props?.overtimeDailyFun(props?.overtimeDailyParameter?.when_posted);
      setTimeout(() => {
        setShowsucess(false);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleComment = (index) => {
    setExpandedComments((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const activityLog = async (table_column, sortType) => {
    try {
      const paramData = {
        activity_id: 47,
        page_type: "ADM Daily Detail",
        application_id: 105,
        table_name: "ADM Daily Detail ",
        table_column: table_column,
        sort_order: sortType === "asc" ? "Ascending" : "Descending",
        payperiod_end_date: props?.tableData?.date_show,
        cost_center_number: props?.tableData?.cost_center_number,
        businessUnit: isBuisnessUnitSet,
      };
      const encryptData = dataEnCrypt(paramData);
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/activity-log/create`,
        { payloadData: encryptData }
      );
    } catch (err) {
      console.log(err);
    }
  };

   const formatDateToDDMMYYYY = (dateString) => {
     return moment(dateString, "DD-MM-YYYY")?.format("MM/DD/YYYY");
   };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="max-w-1020"
        onClick={props.handlClick}
      >
        <Modal.Header
          closeButton
          className="custom-font-size border-0 cros-position"
        >
          {props.isLoading ? (
            <TableLoader themeMode={themeMode} />
          ) : (
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="fw-normal color-black h3 d-flex align-items-top text-center justify-content-center w-100 "
            >
              <div>
                <div className="d-flex justify-content-center align-items-center">
                  UOS Details
                </div>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ fontSize: "20px" }}
                >
                  {props?.costCenterNumber?.label}{" "}
                  {props?.endDate
                    ? `- PPE ${formatDateToDDMMYYYY(props?.endDate)}`
                    : ""}
                </div>
              </div>
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body className="custom-font-size text-indent-15">
          {props.isLoading ? (
            <TargetLoader themeMode={themeMode} />
          ) : (
            <div class=" mt-minus-20">
              <div class="bg-white p-3 pt-0 h-100">
                <div></div>
                <div class="m-0 mt-3 gx-2 row">
                  <div class="col-sm-4">
                    <div class="border p-2 text-center">
                      <h4 class="text-center justify-content-center mb-0">
                        UOS
                      </h4>
                      <h4 class="text-center justify-content-center fw-light f-22">
                        <div className="d-flex justify-content-center align-items-center position-relative">
                          <span>
                            {formatNumberWithFraction(uosHeaderData?.uos, 0)}{" "}
                          </span>
                          &nbsp;
                          <span className="uos-arrow">
                            <img
                            height={19}
                            width={14}
                            style={{verticalAlign: "baseline"}}
                              src={
                                uosHeaderData?.uos_arrow
                                  ? BiArrowUp
                                  : BiArrowDown
                              }
                              alt=""
                            />
                          </span>
                        </div>
                      </h4>
                      <div class="f-14">
                        Budgeted UOS:{" "}
                        {formatNumberWithFraction(
                          uosHeaderData?.budgeted_uos,
                          0
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="border p-2 text-center">
                      <h4 class="text-center justify-content-center mb-0">
                        WORKED FTES
                      </h4>
                      <h4 class="text-center justify-content-center f-22">
                        {formatNumberWithFraction(
                          uosHeaderData?.worked_ftes,
                          1
                        )}
                      </h4>
                      <div class="f-14">
                        Target:{" "}
                        {formatNumberWithFraction(
                          uosHeaderData?.target_worked_ftes,
                          1
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="border p-2 text-center">
                      <h4 class="text-center justify-content-center mb-0">
                        HPUOS
                      </h4>
                      <h4 class="text-center justify-content-center  f-22">
                        {formatNumberWithFraction(uosHeaderData?.hpuos, 3)}
                      </h4>
                      <div class="f-14">
                        Target:{" "}
                        {formatNumberWithFraction(uosHeaderData?.target, 3)}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="table-responsive mt-4">
                  <table
                    class="table activity-tabel mb-0 ind-table f-16  table-hover  table-bordered scroll-table custom-width-table custom-border"
                    style={{ maxHeight: "300px" }}
                  >
                    <thead className="">
                      <tr class="vertical-align-middle border-bottom-1 border-top-0 th-border-0">
                        <th
                          class="text-center cursor-pointer"
                          onClick={() => sortingHandle("job_code")}
                        >
                          <div class="d-flex justify-content-center align-items-center">
                            <div>Job Code</div>
                            <div className="ms-2">
                              {sortType === "asc" && sortName === "job_code" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                        <th
                          class="text-center cursor-pointer"
                          onClick={() => sortingHandle("job_description")}
                        >
                          <div class="d-flex justify-content-center align-items-center">
                            <div>Job Description</div>
                            <div className="ms-2">
                              {sortType === "asc" &&
                              sortName === "job_description" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                        <th
                          class="text-center cursor-pointer"
                          onClick={() => sortingHandle("pay_code")}
                        >
                          <div class="d-flex justify-content-center align-items-center">
                            <div>Pay Code</div>
                            <div className="ms-2">
                              {sortType === "asc" && sortName === "pay_code" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                        <th
                          class="text-center cursor-pointer"
                          onClick={() => sortingHandle("pay_code_description")}
                        >
                          <div class="d-flex  justify-content-center align-items-center">
                            <div>Pay Code Description</div>
                            <div className="ms-2">
                              {sortType === "asc" &&
                              sortName === "pay_code_description" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                        <th
                          class="text-center cursor-pointer"
                          onClick={() => sortingHandle("total_ftes")}
                        >
                          <div class="d-flex justify-content-center align-items-center">
                            <div>Total FTEs</div>
                            <div className="ms-2">
                              {sortType === "asc" &&
                              sortName === "total_ftes" ? (
                                <img src={AscIcon} alt="" />
                              ) : (
                                <img src={DescIcon} alt="" />
                              )}
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="crossi h-150">
                      {uosTableData?.map((val, ind) => {
                        return (
                          <tr>
                            <td class="text-center">{val?.job_code}</td>
                            <td class="text-start">{val?.job_description}</td>
                            <td class="text-center">{val?.pay_code}</td>
                            <td class="text-center">
                              {val?.pay_code_description}
                            </td>
                            <td class="text-center">
                              {formatNumberWithFraction(val?.total_ftes, 1)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>

                    <tbody class="ftr  ">
                      <tr className="bt">
                        <td className="text-center border-0">
                          <strong></strong>
                        </td>
                        <td className="text-center border-0">
                          <strong></strong>
                        </td>

                        <td className="text-center border-0">
                          <strong></strong>
                        </td>
                        {/* {!showComment && (
                        <td className="text-center border-0">
                          <strong></strong>
                        </td>
                      )} */}
                        <td className="text-center border-0">
                          <strong>Total : </strong>
                        </td>
                        <td className="text-center border-0">
                          <strong>
                            {/* {"303.7"} */}
                            {formatNumberWithFraction(hoursTotal(), 1)}
                            {/* {hoursTotal()?.toFixed(2)} */}
                          </strong>
                          {/* <strong> {"7"}</strong> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {props?.tableData?.commentData?.length > 0 && showComment ? (
                    <>
                      <Modal.Title
                        id="contained-modal-title-vcenter"
                        className="fw-normal color-black h3 d-flex align-items-center justify-content-center w-100 "
                      >
                        <img src={Bi5} alt="" height={32} /> &nbsp; COMMENTS
                      </Modal.Title>
                      <div class="overflow-auto">
                        <table class="table activity-tabel mb-0  spacing-table tb1 table-hover comment-table">
                          <thead>
                            <tr>
                              <th class="coll text-center cursor-pointer">
                                <div class="d-flex justify-content-center align-items-center">
                                  <div>
                                    Date
                                    <br />
                                    Submitted
                                  </div>
                                </div>
                              </th>
                              <th class="coll text-center">
                                Submitted
                                <br />
                                By
                              </th>
                              <th class="coll text-center">Employee</th>
                              <th class="coll text-center">Category</th>
                              <th class="coll text-center">
                                Activity
                                <br />
                                Date
                              </th>
                              <th class="coll text-center">Reason</th>
                              <th class="coll text-center">Location</th>
                              <th class="coll text-center">Comment</th>
                              <th class="coll text-center">Approve</th>
                            </tr>
                          </thead>
                          <tbody class="comment-table-height scrollbar">
                            {props?.tableData?.commentData?.map((val, ind) => {
                              const isExpanded = expandedComments[ind];
                              const truncatedComment = val.comment.substring(
                                0,
                                45
                              );
                              const displayComment = isExpanded
                                ? val.comment
                                : truncatedComment;
                              return (
                                <tr>
                                  <td class="text-center ">
                                    {" "}
                                    {moment(val.createdAt).format("MM/DD/YY")}
                                  </td>
                                  <td class="text-center">
                                    {" "}
                                    {val.submitted_by_name}
                                  </td>
                                  <td class="text-center">
                                    {val.employee_name}
                                  </td>
                                  <td class="text-center">{val.category}</td>
                                  <td class="text-center">
                                    {convertDateFormat(val.transaction_date)}
                                  </td>
                                  <td class="text-center">{val.reason}</td>
                                  <td class="text-center">{val.location}</td>
                                  <td class="text-center">
                                    {displayComment}
                                    {val.comment.length > 45 && (
                                      <span
                                        className="shwmore"
                                        onClick={() => toggleComment(ind)}
                                      >
                                        {isExpanded ? (
                                          "Less"
                                        ) : (
                                          <img
                                            src={More}
                                            width={18}
                                            title="Read More"
                                          />
                                        )}
                                      </span>
                                    )}
                                  </td>
                                  <td class="text-center img-cursor-pointer">
                                    {val?.manager_review === 1 ? (
                                      <>
                                        <img
                                          src={Approve}
                                          alt="Approve"
                                          onClick={(e) => {
                                            if (val?.manager_review === 1) {
                                              manageReview(val._id, 0);
                                            } else {
                                              manageReview(val._id, 1);
                                            }
                                          }}
                                        />
                                        &nbsp; &nbsp;
                                        <img
                                          src={Reject}
                                          alt="Reject"
                                          onClick={(e) => {
                                            if (val?.manager_review === 2) {
                                              manageReview(val._id, 0);
                                            } else {
                                              manageReview(val._id, 2);
                                            }
                                          }}
                                        />
                                      </>
                                    ) : val?.manager_review === 2 ? (
                                      <>
                                        <img
                                          src={UnApprove}
                                          alt="UnApprove"
                                          onClick={(e) => {
                                            if (val?.manager_review === 1) {
                                              manageReview(val._id, 0);
                                            } else {
                                              manageReview(val._id, 1);
                                            }
                                          }}
                                        />
                                        &nbsp; &nbsp;
                                        <img
                                          src={UnReject}
                                          alt="UnReject"
                                          onClick={(e) => {
                                            if (val?.manager_review === 2) {
                                              manageReview(val._id, 0);
                                            } else {
                                              manageReview(val._id, 2);
                                            }
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={UnApprove}
                                          alt="UnApprove"
                                          onClick={(e) => {
                                            if (val?.manager_review === 1) {
                                              manageReview(val._id, 0);
                                            } else {
                                              manageReview(val._id, 1);
                                            }
                                          }}
                                        />
                                        &nbsp; &nbsp;
                                        <img
                                          src={Reject}
                                          alt="Reject"
                                          onClick={(e) => {
                                            if (val?.manager_review === 2) {
                                              manageReview(val._id, 0);
                                            } else {
                                              manageReview(val._id, 2);
                                            }
                                          }}
                                        />
                                      </>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <CommentModal
                  show={modalShow}
                  commentData={commentData}
                  dailyData={props?.tableData}
                  overtimeDailyFun={props?.overtimeDailyFun}
                  overtimeDailyParameter={{
                    when_posted: props?.overtimeDailyParameter?.when_posted,
                  }}
                  onHide={() => {
                    setModalShow(false);
                  }}
                />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <SuccessModal
        isShow={showsucess}
        callbackfunc={setShowsucess}
        title="Success"
        content={successMessage}
      />
    </>
  );
}

function CommentModal(props) {
  const [reasonValue, setReasonValue] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [commentValue, setCommentValue] = useState("");
  const [showsucess, setShowsucess] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const LocationData = props?.commentData?.location;
  const ReasonData = props?.commentData?.reason;
  const commentDetail = async () => {
    if (!commentValue) {
      setErrorMsg("Please enter missing data");
    } else {
      try {
        const paramData = {
          category: props?.commentData?.category,
          application_id: 105,
          cost_center_number: props?.dailyData?.cost_center_number,
          when_posted: props?.commentData?.when_posted,
          comment: commentValue,
        };

        const encryptData = dataEnCrypt(paramData);
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/comment/addComment`,
          { payloadData: encryptData }
        );
        const data = decryptedFunction(response?.data?.responseData);
        props.onHide(false);
        setShowsucess(true);
        setSuccessMessage(data.message);
        setTimeout(() => {
          setShowsucess(false);
        }, 2000);
        setReasonValue("");
        setLocationValue("");
        setCommentValue("");
        setErrorMsg("");
        props?.overtimeDailyFun(props?.overtimeDailyParameter?.when_posted);
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="max-w-900"
        onHide={() => {
          props.onHide(false);
          setErrorMsg("");
        }}
      >
        <Modal.Header
          closeButton
          className="border-0 custom-cross-btn position-relative"
        >
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form inline-form className="comment-form">
            <Row>
              <Col md="6">
                <Row>
                  <Col md="12">
                    <Form.Group
                      className="d-flex inline-f mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Category : </Form.Label>
                      <Form.Control
                        type="text"
                        value={props?.commentData?.category}
                        disabled
                        style={{
                          cursor: "not-allowed",
                          textAlign: "left",
                          paddingLeft: 8,
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <Form.Group
                      className="d-flex inline-f mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Date : </Form.Label>
                      <Form.Control
                        type="text"
                        value={props?.commentData?.show_when_posted}
                        disabled
                        style={{
                          cursor: "not-allowed",
                          textAlign: "left",
                          paddingLeft: 8,
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <Row>
                  <Col className="mobile-mt-0" style={{ marginTop: "-30px" }}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Additional Notes:</Form.Label>
                      <Form.Control
                        as="textarea"
                        style={{ textAlign: "left" }}
                        rows={4}
                        onChange={(e) => {
                          setCommentValue(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            onClick={() => {
              props.onHide(false);
              setErrorMsg("");
            }}
            variant="danger"
            className="border-0"
          >
            CANCEL
          </Button>
          <Button
            onClick={commentDetail}
            variant="success"
            className="border-0"
          >
            SUBMIT
          </Button>
          <div style={{ color: "red", width: "100%", textAlign: "right" }}>
            {errorMsg}
          </div>
        </Modal.Footer>
      </Modal>

      <SuccessModal
        isShow={showsucess}
        callbackfunc={setShowsucess}
        title="Success"
        content={successMessage}
      />
    </>
  );
}

export default InfoModal;
