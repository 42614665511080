import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import axiosR from "../../../axios";
import { API_CALL_URL_PRIVATE } from "../../../constants/compensate";
import { API_CALL_URL_PUBLIC } from "../../../constants/compensate";
// import logo from "./logo.jpeg";
import { useSelector, useDispatch } from "react-redux";
import { history, useNavigate, Link } from "react-router-dom";
import {
  userLogin,
  setUpdateUserData,
} from "../../../store/actions/Compensate";
import { useMsal } from "@azure/msal-react";
import { loginValidator } from "../../../utils/validator";
import { InteractionType } from "@azure/msal-browser";
// import ProfileContent from "./ProfileContent";
import { get, isEmpty } from "lodash";
import logo from "./logo.jpeg";
import { Formik, Field, Form } from "formik";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Overlay from "../../../components/Overlay";
import { decryptedFunction, dataEnCrypt } from "../../../utils/helper";

function Index() {
  const { instance } = useMsal(); // Use the context provided by MsalProvider
  // const { access_token } = useParams();
  // const userData = JSON.parse(localStorage.getItem("psdata"));
  const navigate = useNavigate();
  const [errorObj, setErrorObj] = useState({});
  const dispatch = useDispatch();
  const [checkBox, setCheckBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginValues, setLoginValues] = useState({
    email: "",
    password: "",
  });

  const params = new URLSearchParams(window.location.search);
  const access_token = params?.get("access_token");
  const tenantId = params?.get("tenantId");

  const handleLogin = () => {
     setIsLoading(true);
    instance
      .loginPopup({ scopes: ["openid", "profile", "user.read"] })
      .then((loginResponse) => {
         const loginData = {
           username: loginResponse?.account.username,
           tenant_id: loginResponse?.account?.tenantId,
           type: 1,
         };
        loginSubmit(loginData);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const loginSubmit = async (loginData) => {
    window.localStorage.clear();
    window.sessionStorage.clear();

    try {

      const encryptData = dataEnCrypt(loginData);
      let response = await axios.post(
        `${API_CALL_URL_PUBLIC}/authentication/userLogin`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);

      if (!isEmpty(data?.data?.application_accesses)) {
        const urlSlug = data?.data?.application_accesses?.[0]?.slug;
        navigate(`/${urlSlug}`);
      } else {
        navigate("/access-denied");
      }

      localStorage.setItem("accessToken", data?.token?.accessToken);
      localStorage.setItem("psdata", JSON.stringify(data));
      if (data?.data?.business_unit_description !== null) {
        localStorage.setItem(
          "buisnessUnit",
        //   data?.data?.business_unit_description
        );
      }
      dispatch(setUpdateUserData(data));
      setIsLoading(false);
    } catch (error) {
      const errorResponse = decryptedFunction(
        error?.response?.data?.responseData
      );
      toast.error(`${errorResponse?.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    if (access_token) {
      adminLogin(access_token);
    }
  }, [access_token]);



    useEffect(() => {
      const timeoutId = setTimeout(() => {
        handleLogin();
      }, 1000); // 5-second delay

      return () => clearTimeout(timeoutId);
    }, []);

  const adminLogin = async (access_token) => {
    try {
      let { data } = await axios.post(
        `${API_CALL_URL_PUBLIC}/authentication/userLogin`,
        {
          login_access_token: access_token,
          type: 2,
        }
      );

      if (!isEmpty(data?.data?.application_accesses)) {
        const urlSlug = data?.data?.application_accesses?.[0]?.slug;
        navigate(`/${urlSlug}`);
      } else {
        navigate("/access-denied");
      }

      localStorage.setItem("accessToken", data?.token?.accessToken);
      localStorage.setItem("psdata", JSON.stringify(data));
      dispatch(setUpdateUserData(data));
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (error.response.status == 400) {
        setErrorObj(error.response.data);
      }
      console.log({ errorObj });
    }
  };

 return (
   <>
     <div class="dashboard-wrapper full-width h-100vh overflow-hidden">
       {/* <div className="d-flex maintenence-row" style={{backgroundColor: "#FFF"}}>
          <div className="redbox">Scheduled maintenance</div>
          <div className="details-t">Our website will undergo scheduled maintenance on <strong>17-10-2024</strong> from <strong>02:00 AM</strong> to <strong>04:00 AM</strong>. During this period, you may experience temporary disruptions. We apologize for any inconvenience and appreciate your understanding.</div>
        </div> */}
       {/* <div class="maintenance-banner">
          <div class="banner-left">
            <img src={Schmaintaince} alt="" />{" "}
            <span>Scheduled Maintenance</span>
          </div>
          <div class="banner-right">
         
            The website will undergo scheduled maintenance on{" "}
              <span style={{ color: "red", padding: "0 5px" }}>
                {" "}
                10/17/2024{" "}
              </span>{" "}
              from{" "}
              <span style={{ color: "red", padding: "0 5px" }}>02:00AM</span> to
              <span style={{ color: "red", padding: "0 5px" }}>
                05:00AM PST.
              </span>{" "}
              During this period, you may experience temporary disruptions. We
              apologize for any inconvenience.
          
          </div>
        </div> */}

       <div class="container pb-0 h-100vh ">
         <div class="row height-100 justify-content-center align-items-center h-100vh">
           <div class="login-screen max-width-300 ">
             <div class="text-center">
               <img src={logo} alt="" class="img-fluid mb-5" />
             </div>
             <Formik
               enableReinitialize
               initialValues={loginValues}
               validateOnChange
               validate={loginValidator}
               onSubmit={(values) => handleLogin(values, false)}
             >
               {(formikBag) => {
                 return (
                   <Form style={{ width: "100%" }}>
                     <div class="mb-3">
                       <label>Username or Email *</label>
                       <Field name="email">
                         {({ field }) => (
                           <input
                             {...field}
                             type="username"
                             name="username"
                             class="form-control"
                             placeholder="Username or Email"
                             value={formikBag.values.email}
                             onChange={(e) => {
                               formikBag.setFieldValue("email", e.target.value);
                             }}
                           />
                         )}
                       </Field>
                       {formikBag.touched.email && formikBag.errors.email ? (
                         <>
                           <p
                             style={{
                               marginTop: 5,
                               fontSize: 13,
                               color: "red",
                             }}
                           >
                             {formikBag.errors.email}
                           </p>
                         </>
                       ) : null}
                     </div>
                     <div class="mb-3">
                       <label>Password *</label>
                       <Field name="password">
                         {({ field }) => (
                           <input
                             {...field}
                             type="password"
                             name="password"
                             class="form-control"
                             placeholder="Password"
                             value={formikBag.values.password}
                             onChange={(e) => {
                               formikBag.setFieldValue(
                                 "password",
                                 e.target.value
                               );
                             }}
                           />
                         )}
                       </Field>
                       {formikBag.touched.password &&
                       formikBag.errors.password ? (
                         <>
                           <p
                             style={{
                               marginTop: 5,
                               fontSize: 13,
                               color: "red",
                             }}
                           >
                             {formikBag.errors.password}
                           </p>
                         </>
                       ) : null}
                     </div>
                     <div class="mb-3 d-flex align-items-center justify-content-between">
                       <div class="form-check">
                         <label class="form-check-label show">
                           <input
                             type="checkbox"
                             class="form-check-input h-16 show"
                             value={checkBox}
                             onChange={(e) => setCheckBox(e.target.checked)}
                           />{" "}
                           Remember me <i class="input-helper"></i>
                         </label>
                       </div>
                       <Link to="/forgot" class="hover-text">
                         Forgot Password
                       </Link>
                     </div>
                     <div class="col-12">
                       <button
                         type="submit"
                         class="btn btm btn-success w-100 text-capitalize"
                         style={{ marginBottom: "8px" }}
                       >
                         Login
                       </button>
                     </div>
                     <div>
                       <div
                         class="col-12 d-flex align-items-center justify-content-center"
                         style={{ color: "lightgray", marginBottom: "5px" }}
                       >
                         or
                       </div>
                       <div class="col-12 d-flex align-items-center justify-content-center hover-text">
                         <Link
                           to="/login/sso"
                           class="forgot-pass f-18"
                           style={{ color: "#5e8e28" }}
                         >
                           Continue with SSO
                         </Link>
                       </div>
                     </div>
                     {/* <div class="col-12">
                        <button
                          type="button"
                          onClick={handleLogin}
                          class="btn btm btn-primary w-100 text-capitalize mt-4"
                        >
                          Login With SSO
                        </button>
                      </div> */}
                   </Form>
                 );
               }}
             </Formik>
           </div>
         </div>
       </div>
       {isLoading && <Overlay />}
     </div>
     <ToastContainer />
   </>
 );
}

export default Index;
