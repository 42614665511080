import "./index.css";
import React from "react";
import { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FooterModal from "../../components/ActiveDailyManagementComponents/shared/footerModal";
import DateFilter from "../../components/DateFilter/RealStatusFilter";
import CensusLineChart from "../../components/ActiveDailyManagementComponents/shared/CensusLineChart/index";
import ContentLoader from "react-content-loader";
import PageLayout from "../../layouts/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get, isEmpty } from "lodash";
import { API_CALL_URL } from "../../constants";
import { API_CALL_URL_PRIVATE } from "../../constants";
import axios from "../../axios";
import Axios from "axios";
import { getTableRecordWithSorting } from "../../services";
import moment from "moment";
import {
  capitalizeAllLetters,
  formatNumberWithFraction,
} from "../../utils/helper";
import {
  setUpdateDate,
  setUpdateIndex,
  setUpdateStack,
  setExceptionIndex,
} from "../../store/actions/Compensate";
import DescIcon from "../../assets/img/desc.png";
import AscIcon from "../../assets/img/asc.png";
import { ThemeContext } from "../../providers/ThemeProvider";
import PsLogo from "../../assets/img/pslogo.png";
import ADMImage from "../../assets/img/real_time.svg";
import Modal from "react-bootstrap/Modal";
import {
  setAirlines,
  setHeadCount,
  setFooterHome,
} from "../../store/actions/Compensate";
import SHIFT_FORM from "../../constants/shiftForm";
import { decryptedFunction, dataEnCrypt } from "../../utils/helper";

const TableLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 8"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="8" />
  </ContentLoader>
);

function ActiveManagement({ history }) {
  const dispatch = useDispatch();
  const buisnessHeader = useSelector(
    (state) => state.compensate.buisnessHeader
  );
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [footerShow, setFooterShow] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableApiData, setTableApiData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [sortType, setSortType] = useState("asc");
  const [sortName, setsortName] = useState("");
  const navigate = useNavigate();
  const [metaData, setMetaData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [dropdownValueThree, setDropdownValueThree] = useState("");
  const [buisnessData, setBuisnessData] = useState({});
  const [isRealtime, setIsRealtime] = useState(true);
  const [filterdate, setFilterdate] = useState({});
  const [realtimeData, setRealtimeData] = useState([]);
  const userData = JSON.parse(localStorage.getItem("psdata"))?.data;
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const [modalShow4, setModalShow4] = React.useState(false);
  const [currentModalData, setCurrentModalData] = useState("");
  const [mounted, setMounted] = useState(false);
  const [csvUpdateEvent, seCsvUpdateEvent] = useState("true");
  const [sliderFilterData, setSliderFilterData] = useState({});
  const [boxColor, setBoxColor] = useState({ color: "" });
  const [gridcolor, setGridcolor] = useState("");
  const [activefiltertab, setActivefiltertab] = useState("realtime");

  function AvailableBedsModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="witoutTitle p-0 height-0">
          <Modal.Title id="contained-modal-title-vcenter"> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 resp">
          <CensusLineChart
            modalGraphData={currentModalData}
            type={"availbleBeds"}
            label={
              userData?.customer_masters?.available_beds_label
                ? userData?.customer_masters?.available_beds_label
                : "Beds Available"
            }
            dataLabel={
              userData?.customer_masters?.available_beds_label
                ? userData?.customer_masters?.available_beds_label
                : "Beds Available"
            }
          />
        </Modal.Body>
      </Modal>
    );
  }

  function CensusdModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="witoutTitle p-0 height-0">
          <Modal.Title id="contained-modal-title-vcenter"> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 resp">
          <CensusLineChart
            modalGraphData={currentModalData}
            type={currentModalData?.graph_type}
            label={currentModalData?.field_label}
            dataLabel={currentModalData?.field_label}
          />
        </Modal.Body>
      </Modal>
    );
  }
  function RNAvailableHoursModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="witoutTitle p-0 height-0">
          <Modal.Title id="contained-modal-title-vcenter"> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 resp">
          <CensusLineChart
            modalGraphData={currentModalData}
            type={"rnHours"}
            label={
              userData?.customer_masters?.rn_available_hours_label
                ? userData?.customer_masters?.rn_available_hours_label
                : "RNs Available"
            }
            dataLabel={
              userData?.customer_masters?.rn_available_hours_label
                ? userData?.customer_masters?.rn_available_hours_label
                : "RNs Available"
            }
          />
        </Modal.Body>
      </Modal>
    );
  }
  function GridRNVarianceModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="witoutTitle p-0 height-0">
          <Modal.Title id="contained-modal-title-vcenter"> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 resp">
          <CensusLineChart
            modalGraphData={currentModalData}
            type={"rnVariance"}
            label={
              userData?.customer_masters?.grid_rn_variance_label
                ? userData?.customer_masters?.grid_rn_variance_label
                : "Grid Variance"
            }
            dataLabel={
              userData?.customer_masters?.grid_rn_variance_label
                ? userData?.customer_masters?.grid_rn_variance_label
                : "Grid Variance"
            }
          />
        </Modal.Body>
      </Modal>
    );
  }
  function EpicScoreModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="witoutTitle p-0 height-0">
          <Modal.Title id="contained-modal-title-vcenter"> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 resp">
          <CensusLineChart
            modalGraphData={currentModalData}
            type={"epicScore"}
            label={
              userData?.customer_masters?.acuity_score_label
                ? userData?.customer_masters?.acuity_score_label
                : "Acuity Score"
            }
            dataLabel={
              userData?.customer_masters?.acuity_score_label
                ? userData?.customer_masters?.acuity_score_label
                : "Acuity Score"
            }
          />
        </Modal.Body>
      </Modal>
    );
  }

  var param = {
    fromdate: {
      date: moment(new Date()).format("DD-MM-YYYY"),
      shift: "03:00",
    },
    todate: { date: moment(new Date()).format("DD-MM-YYYY"), shift: "07:00" },
  };

  const sortingHandle = (key, bindname = null) => {
    const dataToSort = bindname ? get(tableData, bindname, []) : tableData;
    const sortdata = getTableRecordWithSorting(dataToSort, key, sortType);
    setSortType(sortType === "asc" ? "desc" : "asc");

    if (bindname) {
      setTableData({ ...tableData, [bindname]: sortdata });
    } else {
      setTableData(sortdata);
    }
    setsortName(key);
    activityLog(SHIFT_FORM.REALTIME_TABLE[key] ?? "", sortType);
  };

  useEffect(() => {
    document.title = metaData?.meta_title || "Realtime";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);

  const getRefreshSchedule = async () => {
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/fileRefreshSchedule`
      );
      setScheduleData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const buissnessUnitUpdate = async (value) => {
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/businessUnitDescriptionUpdate`,
        {
          business_unit_description: value,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isBuisnessUnitSet !== null) {
      setDropdownValueThree({
        label: isBuisnessUnitSet,
        value: isBuisnessUnitSet,
      });
    }
  }, [isBuisnessUnitSet]);

  useEffect(() => {
    fetchDetails();
    getMetaData();
  }, []);

  const getPreAndCurrShift = async () => {
    try {
      const resp = await axios.post(
        API_CALL_URL_PRIVATE + "/dashboard/getCurrentShift"
      );
      const data = decryptedFunction(resp?.data?.responseData);

      const result = data.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchDetails(searchkey) {
    try {
      setIsTableLoading(true);
      dispatch(setAirlines([]));
      if (activefiltertab == "realtime") {
        setIsRealtime(true);
        const dataawait = await getPreAndCurrShift();
        param = dataawait?.filterDates;
      } else {
        setIsRealtime(false);
        param["fromdate"] = {
          date: moment(sliderFilterData.date).format("DD-MM-YYYY"),
          shift: "03:00",
        };
        param["todate"] = {
          date: moment(sliderFilterData.date).format("DD-MM-YYYY"),
          shift: "23:00",
        };
      }

      if (searchkey) {
        param["searchKeyword"] = searchkey;
      }

       if (isBuisnessUnitSet) {
         param["businessUnit"] = isBuisnessUnitSet;
       }
      //param["searchKeyword"] = "icu";
      param["page_type"] = "real_time_status";
      const fromdatearr = param?.fromdate?.date.split("-");
      const fromdate = `${fromdatearr[1]}/${fromdatearr[0]}/${fromdatearr[2]}`;
      const todatearr = param?.todate?.date.split("-");
      const todate = `${todatearr[1]}/${todatearr[0]}/${todatearr[2]} ${param?.todate?.shift}`;
      setFilterdate({ fromdate, todate });

      const encryptData = dataEnCrypt(param);
      const response = await axios.post(
        API_CALL_URL_PRIVATE + "/dashboard/getData",
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setTableData(data?.data?.content ? data?.data?.content : []);
      setTableApiData(data?.data);
      dispatch(setAirlines(data?.data?.content ? data?.data?.content : []));
      dispatch(setHeadCount(data?.data?.header));
      dispatch(setFooterHome(data?.data?.footer));

      if (!searchkey) {
        if (userData?.realtime_landing === 0) {
          const slug = data?.data?.content[0]?.slug;
          navigate(`/realtime/department-details/${slug}/realtime`);
        }
      }

      setIsTableLoading(false);
    } catch (error) {
      setIsTableLoading(false);
      console.log({ error });
      // debugger
    }
  }

  const getMetaData = async () => {
    try {
      const param = {
        application_id: 101,
      };
      const encryptData = dataEnCrypt(param);
      const response = await axios.post(`${API_CALL_URL_PRIVATE}/metaData`, {
        payloadData: encryptData,
      });
      const data = decryptedFunction(response?.data?.responseData);
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  function isactivefilter(addclass) {
    setGridcolor(addclass);
  }

  useEffect(() => {
    fetchDetails();
  }, [sliderFilterData]);

    useEffect(() => {
      if (mounted) {
        fetchDetails();
      } else {
        setMounted(true);
      }
    }, [buisnessHeader]);

  const activityLog = async (table_column, sortType) => {
    try {
      const paramData = {
        activity_id: 47,
        page_type: "Realtime Status",
        application_id: 105,
        table_name: "Realtime Status",
        table_column: table_column,
        sort_order: sortType === "asc" ? "Ascending" : "Descending",
      };
      const encryptData = dataEnCrypt(paramData);
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/activity-log/create`,
        { payloadData: encryptData }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="newrealtime">
      <PageLayout>
        <div className="activedailymanagement-s">
          <Row className="gx-2 mt-0 align-items-center">
            {/* <Col md={3} className=" h-45p"></Col> */}
            <Col md={3}>
              <Link
                to={`/realtime`}
                className="f-14 white-text-dark-mode"
              >
                <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
              </Link>
            </Col>
            <Col md={{ span: 6 }}>
              <div className="text-center py-3">
                <h3 className="text-center color-black m-0 d-flex align-items-center justify-content-center f-25rem">
                  <img src={ADMImage} alt="" height={38} /> &nbsp; RealTime
                  Status
                </h3>
              </div>
            </Col>
            <Col md={3} className="d-flex justify-content-end h-45p">
              {userData?.user_type === 0 && (
                <div className="row side-gap">
                  {Object.keys(filterdate).length > 0 ? (
                    <div className="col-md-12">
                      <div className="recordshowing mt-0">
                        Record showing : {filterdate.fromdate}
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-12">
                    <DateFilter
                      fromdate={filterdate.fromdate}
                      seCsvUpdateEvent={seCsvUpdateEvent}
                      setSliderFilterData={setSliderFilterData}
                      setBoxColor={setBoxColor}
                      isactivefilter={isactivefilter}
                      active={activefiltertab}
                      activetab={(tab) => setActivefiltertab(tab)}
                    />
                  </div>
                </div>
              )}
            </Col>

            <FooterModal
              show={footerShow}
              dataFooter={scheduleData}
              onHide={() => setFooterShow(false)}
            />
          </Row>

          <Row className="gx-2">
            <Col md={12} className="mt-1 ">
              <div className="bg-white p-2 pt-0">
                {/* <div class="overlfow-auto h-670"> */}
                <div class="overflow-auto">
                  <table class="table activity-tabel mb-0  spacing-table tb1 table-hover realtime-table text-break ">
                    <thead>
                      {!isTableLoading ? (
                        <tr>
                          <th
                            class="coll text-center cursor-pointer"
                            onClick={() => sortingHandle("cost_center_name")}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              <div>Cost Center</div>
                              <div className="ms-2">
                                {sortType === "asc" &&
                                sortName === "cost_center_name" ? (
                                  <img src={AscIcon} alt="" />
                                ) : (
                                  <img src={DescIcon} alt="" />
                                )}
                              </div>
                            </div>
                          </th>
                          <th class="coll text-center">Interval</th>
                          {/* <th class="coll text-center">
                          {userData?.customer_masters?.current_census_label
                            ? userData?.customer_masters?.current_census_label
                            : "Census"}
                        </th>
                        <th class="coll text-center">
                          {userData?.customer_masters?.rn_available_hours_label
                            ? userData?.customer_masters
                                ?.rn_available_hours_label
                            : "RNs Available"}
                        </th>
                        <th class="coll text-center">
                          {userData?.customer_masters?.grid_rn_variance_label
                            ? userData?.customer_masters?.grid_rn_variance_label
                            : "Grid Variance"}
                        </th>
                        <th class="coll text-center">
                          {userData?.customer_masters?.available_beds_label
                            ? userData?.customer_masters?.available_beds_label
                            : "Beds Available"}
                        </th>
                        <th class="coll text-center">
                          {userData?.customer_masters?.acuity_score_label
                            ? userData?.customer_masters?.acuity_score_label
                            : "Acuity Score"}
                         

                        </th>
                        <th class="coll text-center">Productivity</th> */}
                          {tableApiData?.header?.tableHeaders?.map(
                            (val, ind) => (
                              <th className="coll text-center" key={ind}>
                                {val?.field_label}
                              </th>
                            )
                          )}
                        </tr>
                      ) : (
                        <div>
                          <TableLoader themeMode={themeMode} />
                        </div>
                      )}
                    </thead>
                    <tbody className="h-540 scrollbar">
                      {!isTableLoading ? (
                        tableData?.length > 0 ? (
                          tableData?.map((val, ind) => {
                            return (
                              <tr key={ind}>
                                <td
                                  class="text-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(
                                      `/realtime/department-details/${val?.slug}/realtime`
                                    );
                                  }}
                                >
                                  <div class="cost-center-name">
                                    {val?.cost_center_name}
                                  </div>
                                </td>
                                <td class="text-center">
                                  <span class="v-m">{val?.shift_interval}</span>
                                </td>
                                {/* <td
                                  class="text-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentModalData(val);
                                    setModalShow1(true);
                                  }}
                                >
                                  {val?.shift_census}
                                </td>
                                <td
                                  class="text-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentModalData(val);
                                    setModalShow2(true);
                                  }}
                                >
                                  {val?.shift_rn_available_hours?.toFixed(2)}
                                </td>
                                <td
                                  class="text-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentModalData(val);
                                    setModalShow3(true);
                                  }}
                                >
                                  {val?.shift_grid_rn_variance}
                                </td>
                                <td
                                  class="text-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentModalData(val);
                                    setModalShow(true);
                                  }}
                                >
                                  {val?.shift_available_beds}
                                </td>
                                <td
                                  class="text-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentModalData(val);
                                    setModalShow4(true);
                                  }}
                                >
                                  {val?.shift_epic_score}
                                </td> */}
                                {val?.dynamic_fields?.map((field, ind) => (
                                  <>
                                    {field?.key === "outer_ring" &&
                                    val?.cost_center_category === 6 ? (
                                      // Do not render anything for outer_ring if cost_center_category is 6
                                      <td
                                        className="text-center"
                                        key={ind}
                                      ></td>
                                    ) : (
                                      <td
                                        className="text-center"
                                        style={{
                                          cursor:
                                            field?.key ===
                                              "shift_rn_available_hours" &&
                                            val?.cost_center_category === 6
                                              ? "cursor-pointer"
                                              : "pointer",
                                        }}
                                        key={ind}
                                        onClick={(e) => {
                                          if (
                                            field?.key !==
                                              "shift_rn_available_hours" ||
                                            val?.cost_center_category !== 6
                                          ) {
                                            e.stopPropagation();
                                            setCurrentModalData(field);
                                            setModalShow1(true);
                                          }
                                        }}
                                      >
                                        {field?.key === "outer_ring" ? (
                                          // Show productivity circle if it is not outer_ring with cost_center_category 6
                                          field?.value >= 100 ? (
                                            <span className="green-productive circle-30"></span>
                                          ) : field?.value >= 98 ? (
                                            <span className="yellow-productive circle-30"></span>
                                          ) : (
                                            <span className="red-productive circle-30"></span>
                                          )
                                        ) : field?.key ===
                                            "shift_rn_available_hours" &&
                                          val?.cost_center_category === 6 ? (
                                          // Show "N/A" if shift_rn_available_hours is 0 and cost_center_category is 6
                                          field?.value === 0 ? (
                                            "N/A"
                                          ) : (
                                            field?.value
                                          )
                                        ) : (
                                          // Show the field value for all other cases
                                          field?.value
                                        )}
                                      </td>
                                    )}
                                  </>
                                ))}

                                {/* <td class="text-center">
                                  {val?.outer_ring >= 100 && (
                                    <span className="green-productive circle-30"></span>
                                  )}
                                  {val?.outer_ring < 100 &&
                                    val?.outer_ring >= 98 && (
                                      <span className="yellow-productive circle-30"></span>
                                    )}
                                  {val?.outer_ring < 98 && (
                                    <span className="red-productive circle-30"></span>
                                  )}
                                </td> */}
                              </tr>
                            );
                          })
                        ) : (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "200px" }}
                          >
                            <p className="text-secondary display-6">
                              No Data Found
                            </p>
                          </div>
                        )
                      ) : (
                        <div>
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                          <TableLoader themeMode={themeMode} />
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <CensusdModal show={modalShow1} onHide={() => setModalShow1(false)} />

        <AvailableBedsModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <RNAvailableHoursModal
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        />
        <GridRNVarianceModal
          show={modalShow3}
          onHide={() => setModalShow3(false)}
        />
        <EpicScoreModal show={modalShow4} onHide={() => setModalShow4(false)} />
      </PageLayout>
    </div>
  );
}

export default ActiveManagement;

