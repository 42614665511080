import { useContext } from "react";
import Chart from "react-apexcharts";
import ContentLoader from "react-content-loader";
import { ThemeContext } from "../../../providers/ThemeProvider";
import { formatNumberWithFraction } from "../../../utils/helper";


import moment from "moment";

const MyLoader = ({ themeMode }) => (
  <ContentLoader viewBox="0 0 100 25"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="1" rx="2" ry="2" width="100" height="24" />
  </ContentLoader>
);

function BiweeklyLineChart({
  admitlinechartat,
  admitlinechartseries,
  isLoader,
  color,
  title,
  data,
  minData,
  maxMaxData,
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);


   const formattedData = data?.graphData?.map((data) => ({
     actual_worked_ftes: data.actual_worked_ftes,
     target_worked_ftes: data.target_worked_ftes,
     payPeriodEndDate: data.payPeriodEndDate,
   }));

   


  var options = {
    chart: {
      width: 310,
      height: '310px',
      type: "line",
      stacked: false,

      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Disable zooming behavior
      },
    },

    dataLabels: {
      enabled: true,
      hideOverflowingLabels: true,
      formatter: function (val) {
        // return val + "%";
        return formatNumberWithFraction(val);
      },

      offsetY: -5,
      style: {
        fontSize: "14px",
        fontWeight: 'normal',
        colors: themeMode.theme === "DARK" ? ["white"] : ["black"],
        padding: {
          top: 0,
        },
      },
      background: {
        enabled: false,
      },
    },
    colors: ["#f1b017", "#6ca9d6"],

    stroke: {
      width: [2, 2],
    },
    markers: {
      size: 6,
      strokeColors: "#fff",
      hover: {
        size: 6,
        sizeOffset: 3,
      },
    },

    plotOptions: {
      bar: {
        columnWidth: "20%",
      },
    },
    xaxis: {
      categories: data?.monthResponse?.displayMonths,
      tickAmount: data?.monthResponse?.displayMonths?.length,
      type: "categories",
      title: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
          colors: themeMode.theme === "DARK" ? "white" : "black",
        },
      },
      tickPlacement: "between",
    },
    yaxis: {
      show: true,
      labels: {
        formatter: function (val) {
          return formatNumberWithFraction(val, 0);
        },
        style: {
          colors: themeMode.theme === "DARK" ? "white" : "black",
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        // Display the original `payPeriodEndDate` in tooltip
        formatter: (value, { dataPointIndex }) => {
          return formattedData[dataPointIndex]?.payPeriodEndDate || value;
        },
      },
      y: {
        enabled: false,
        formatter: (value) => `  ${formatNumberWithFraction(value, 1)}`, // Tooltip for Y-axis
      },
    },
    legend: {
      labels: {
        colors: themeMode.theme === "DARK" ? "white" : "black",
        useSeriesColors: false,
      },
      horizontalAlign: "center",
      position: "top",
      offsetY: 20,
      offsetX: 0,
    },

    annotations: {
      yaxis: [
        {
          y: data?.averageTargetWorkedFtes,
          strokeDashArray: 8,
          borderWidth: 1,
          borderColor: themeMode.theme === "DARK" ? "#f1b017" : "#f1b017",

          // label: {
          //   borderColor: "#f1b017",
          //   textAnchor: "start",
          //   position: "left",
          //   style: {
          //     color: "#000",
          //     background: "#f1b017",
          //   },
          //   text: "Avg. Target Worked FTEs",
          // },
        },
        {
          y: data?.averageActualWorkedFtes,
          strokeDashArray: 8,
          borderWidth: 1,
          borderColor: themeMode.theme === "DARK" ? "#6ca9d6" : "#6ca9d6",

          // label: {
          //   borderColor: "#6ca9d6",
          //   textAnchor: "start",
          //   position: "left",
          //   style: {
          //     color: "#000",
          //     background: "#6ca9d6",
          //   },
          //   text: "Avg. Actual Worked FTEs",
          // },
        },
      ],
    },
  };
  
  return (
    <div>
      {!isLoader ? (
        <div className="bg-white pb-0 ps-2 pe-2">
          <div
            class="f-14 text-center mb-minus-10 font-w-500"
            style={{ color: "themeMode.theme === 'DARK' ? 'white' : 'black'," }}
          >
            {title}
          </div>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <div className="graph"  style={{ width: "100%" }}>
              <Chart
                options={options}
                series={[
                  {
                    name: "Target Worked FTEs",
                    data: formattedData?.map((item) => item?.target_worked_ftes),
                  },
                  {
                    name: "Actual Worked FTEs",
                    data: formattedData?.map(
                      (item) => item?.actual_worked_ftes
                    ),
                  },
                ]}
                type="line"
                //  width={"100%"}
                 height={310}
                // height='100%'
              />
            </div>
          </div>
        </div>
      ) : (
        <MyLoader themeMode={themeMode} />
      )}
    </div>
  );
}

export default BiweeklyLineChart;
