import { useContext } from "react";
import Chart from "react-apexcharts";
import ContentLoader from "react-content-loader";
import { ThemeContext } from "../../../providers/ThemeProvider";
import {
  formatNumberWithFraction,
} from "../../../utils/helper";


import moment from "moment";

const MyLoader = ({ themeMode }) => (
  <ContentLoader viewBox="0 0 100 25"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="1" rx="2" ry="2" width="100" height="24" />
  </ContentLoader>
);

function LineChart({
  admitlinechartat,
  admitlinechartseries,
  isLoader,
  color,
  title,
  data,
  minData,
  maxMaxData,
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);

   const formatLargeNumbers = (value) => {
     if (value >= 1_000_000_000) {
       return (value / 1_000_000_000).toFixed(0) + "B"; // Billion
     } else if (value >= 1_000_000) {
       return (value / 1_000_000).toFixed(0) + "M"; // Million
     } else if (value >= 1_000) {
       return (value / 1_000).toFixed(0) + "K"; // Thousand
     }
     return value; // Smaller values are returned as-is
   };

   const millionLargeNumbers = (value) => {
     return (value / 1_000_000).toFixed(1) + "M"; // Convert everything to Million
   };


   const formattedData = data?.graphData?.map((data) => ({
     x: moment(data.payPeriodEndDate, "MM/DD/YYYY").format("MMM YYYY"), // For X-axis display
     originalDate: data.payPeriodEndDate, // Store original date for tooltip
     y: data.rolling4_stoploss, // Y-axis value
   }));



  var options = {
    chart: {
      // height: 350,
      type: "line",
      stacked: false,
      color: themeMode.theme === "DARK" ? "white" : "black",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Disable zooming behavior
      },
    },

    colors: ["#6ca9d6"],

    stroke: {
      curve: "smooth",
      width: [2, 2],
    },
    markers: {
      size: 6,
      strokeColors: "#fff",
      hover: {
        size: 6,
        sizeOffset: 3,
      },
    },

    plotOptions: {
      bar: {
        columnWidth: "20%",
      },
    },
    dataLabels: {
      enabled: true,
      hideOverflowingLabels: true,
      formatter: function (val) {
        // return val + "%";
        return "$" + formatLargeNumbers(val);
      },

      offsetY: -10,
      style: {
        fontSize: "12px",
        colors: themeMode.theme === "DARK" ? ["white"] : ["black"],
        padding: {
          top: 30,
        },
      },
      background: {
        enabled: false,
      },
    },
    xaxis: {
      categories: data?.monthResponse?.displayMonths,
      tickAmount: data?.monthResponse?.displayMonths?.length,
      type: "categories",
      title: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
          colors: themeMode.theme === "DARK" ? "white" : "black",
        },
        show: true,
      },
      tickPlacement: "between",
    },
    yaxis: {
      show: true,
      labels: {
        formatter: function (val) {
          return "$" + formatLargeNumbers(val);
        },
        style: {
          colors: themeMode.theme === "DARK" ? "white" : "black",
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        // Display the original `payPeriodEndDate` in tooltip
        formatter: (value, { dataPointIndex }) => {
          return formattedData[dataPointIndex]?.originalDate || value;
        },
      },
      y: {
        enabled: false,
        formatter: (value) => `  $${formatNumberWithFraction(value, 0)}`, // Tooltip for Y-axis
      },
    },
    legend: {
      labels: {
        colors: themeMode.theme === "DARK" ? "white" : "black",
        useSeriesColors: false,
      },
      horizontalAlign: "center",
      position: "top",
      offsetY: 20,
      offsetX: 0,
    },

    // annotations: {
    //   yaxis: [
    //     {
    //       y: data?.averageStopLoss,
    //       strokeDashArray: 8,
    //       borderWidth: 1,
    //       borderColor: themeMode.theme === "DARK" ? "#e0d61e90" : "#e0d61e90",

    //       label: {
    //         borderColor: "#6ca9d6",
    //         textAnchor: "start",
    //         position: "left",
    //         style: {
    //           color: "#000",
    //           background: "#6ca9d6",
    //         },
    //         text: `Average: ${
    //           data?.averageStopLoss
    //             ? formatNumberWithFraction(data?.averageStopLoss, 1)
    //             : "0"
    //         }`,
    //       },
    //     },
    //   ],
    // },
  };
  
  return (
    <div className="h-100">
      {!isLoader ? (
        <div className="bg-white pb-0 ps-2 pe-2 h-100">
          <div
            class="f-14 text-center mb-minus-10 font-w-500 "
            style={{ color: "themeMode.theme === 'DARK' ? 'white' : 'black'," }}
          >
            {title}
          </div>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%", height: "100%"  }}>
              <Chart
                options={options}
                series={[
                  {
                    name: "Rolling - 4 Stop Loss Opportunity",
                    data: formattedData?.map((item) => item.y),
                  },
                ]}
                type="line"
                //  width={"100%"}
                // height={135}
                height="100%"
              />
            </div>
          </div>
        </div>
      ) : (
        <MyLoader themeMode={themeMode} />
      )}
    </div>
  );
}

export default LineChart;
