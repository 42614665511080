import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import React, { useState } from "react";
import "./index.css";
import "font-awesome/css/font-awesome.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DateFilter({
  setSliderFilterData,
  isactivefilter,
  activetab,
  active,
  seCsvUpdateEvent,
  cost_center_number,
  fromdate,
}) {
  const time = fromdate ? new Date(fromdate) : "";
  const [startDate, setStartDate] = useState("");
  const [InputRangeValue, setInputRange] = useState({ min: 0, max: 0 });
  const todayDate = new Date().toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });
  const momentDate = moment(todayDate, "MM/DD/YYYY, h:mm:ss A");
  const formattedDate = momentDate.toDate();
  const handleClick = (event) => {
    //setActive(event.target.id);
    activetab(event.target.id);
    window?.localStorage?.setItem("activefiltertab", event.target.id);
    if (event.target.id == "realtime") {
      //console.log(time, 'time');
      setStartDate(formattedDate);
      setSliderFilterData({ ...InputRangeValue, active: "realtime" });
    }
  };

  const datepickerHandler = (date) => {
    setStartDate(date);
    activetab("datepicker");
    setSliderFilterData({ ...InputRangeValue, date, active: "datepicker" });
  };

  return (
    <div className="d-flex timeline mt-2">
      <div className="col flex-grow-0 me-2" id="pills-tabContent">
        <div className="col-md-12">
          <DatePicker
            showIcon
            maxDate={formattedDate}
            dateFormat="MM/dd/Y"
            className={active == "datepicker" ? "active" : ""}
            selected={startDate == "" ? time : startDate}
            onChange={(date) => datepickerHandler(date)}
          />
        </div>
        {/*<div className="col-md-4"><button onClick={() => fetchDetails()} className='btn btn-primary'>Search</button></div> */}
      </div>
      <ul
        className="col  fitcontent nav nav-pills "
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <button
            key={"realtime"}
            onClick={handleClick}
            id={"realtime"}
            className={
              active == "realtime"
                ? "nav-link me-1 active"
                : "nav-link me-1 primary-font"
            }
            data-bs-toggle="pill"
            data-bs-target="#pills-w"
            type="button"
            aria-controls="pills-w"
          >
            Realtime
          </button>
        </li>
      </ul>
    </div>
  );
}

export default DateFilter;
