import "./index.css";
import React from "react";
import { useEffect, useContext, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import InfoModal from "../../../components/ActiveDailyManagementComponents/shared/InfoModal";
import FooterModal from "../../../components/ActiveDailyManagementComponents/shared/footerModal";
import BiweeklyLineChart from "../../../components/shared/BiweeklyLineChart/inde";
import BiweeklyLineChart2 from "../../../components/shared/BiweeklyLineChart/index2";
import ContentLoader from "react-content-loader";
import PageLayout from "../../../layouts/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get, isEmpty } from "lodash";
import { API_CALL_URL } from "../../../constants";
import { API_CALL_URL_PRIVATE } from "../../../constants";
import axios from "../../../axios";
import Axios from "axios";
import { getTableRecordWithSorting } from "../../../services";
import {
  capitalizeAllLetters,
  formatNumberWithFraction,
} from "../../../utils/helper";
import {
  setUpdateDate,
  setUpdateIndex,
  setUpdateStack,
  setExceptionIndex,
} from "../../../store/actions/Compensate";
import { ThemeContext } from "../../../providers/ThemeProvider";
import PsLogo from "../../../assets/img/pslogo.png";
import LineChart from "../../../assets/img/grap-line.jpg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SHIFT_FORM from "../../../constants/shiftForm";
import {
  CustomDropdown,
  DropdownIndicator,
  ChevronDown,
} from "../../../Styled/DropdownStyle";
import ButtonKit from "@atlaskit/button";
import Select from "react-select";
import { decryptedFunction, dataEnCrypt } from "../../../utils/helper";
import Button from "@atlaskit/button";
import moment from "moment";

const HeaderLoader = () => (
  <ContentLoader viewBox="0 0 280 17">
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="17" />
  </ContentLoader>
);

const HeaderLoaderText = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 10"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="10" />
  </ContentLoader>
);

const TableLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 8"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="8" />
  </ContentLoader>
);
const GraphLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 50"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="90" />
  </ContentLoader>
);

const LinkLoader = ({ themeMode }) => (
  <div style={{ width: 40, height: 20 }}>
    <ContentLoader
      viewBox="0 0 80 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
    </ContentLoader>
  </div>
);
const options = [
  { value: "option1", label: "2020" },
  { value: "option2", label: "2021" },
  { value: "option3", label: "2022" },
  { value: "option4", label: "2023" },
  { value: "option5", label: "2024" },
];

function FTETrends({ history }) {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const isFirstRender = useRef(true);
  const buisnessHeader = useSelector(
    (state) => state.compensate.buisnessHeader
  );
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [modalShow, setModalShow] = React.useState(false);
  const [footerShow, setFooterShow] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [totalvalue, setTotalvalue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [sortType, setSortType] = useState("asc");
  const [sortName, setsortName] = useState("");
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [metaData, setMetaData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [costCenterList, setCostCenterList] = useState([]);
  const [page, setPage] = useState(1);
  const [cancelToken, setCancelToken] = useState(null);
  const [buisnessData, setBuisnessData] = useState({});
  const [mounted, setMounted] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(undefined);
  const [isDropdownOpentwo, setIsDropdownOpentwo] = useState(false);
  const [dropdownValueTwo, setDropdownValueTwo] = useState(undefined);
  const [isDropdownOpenthree, setIsDropdownOpenThree] = useState(false);
  const [dropdownValueThree, setDropdownValueThree] = useState(undefined);
  const [isDropdownOpenfour, setIsDropdownOpenFour] = useState(false);
  const [dropdownValueFour, setDropdownValueFour] = useState(undefined);

  const [isDropdownOpenfive, setIsDropdownOpenFive] = useState(false);
  const [dropdownValueFive, setDropdownValueFive] = useState(undefined);

  const [isDropdownOpensix, setIsDropdownOpenSix] = useState(false);
  const [dropdownValueSix, setDropdownValueSix] = useState(undefined);

  const [isDropdownOpenseven, setIsDropdownOpenSeven] = useState(false);
  const [dropdownValueSeven, setDropdownValueSeven] = useState(undefined);
  const [startDateValue, setStartDateValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);
  const [isDropdownOpeneight, setIsDropdownOpenEight] = useState(false);
  const [dropdownValueEight, setDropdownValueEight] = useState(undefined);

  const [selectedOption, setSelectedOption] = useState(null);
  const [vpList, setVpList] = useState([]);
  const [executiveList, setExecutiveList] = useState([]);
  const [directorList, setDirectorList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [executiveValue, setExecutiveValue] = useState(null);
  const [vpValue, setVpValue] = useState(null);
  const [directorValue, setDirectorValue] = useState(null);
  const [managerValue, setManagerValue] = useState(null);
  const [yearDateList, setYearDateList] = useState([]);
  const [payperiodDateList, setPayperiodDateList] = useState([]);
  const [actualTargetGraph, setActualTargetGraph] = useState([]);
  const [otEduAgeOriGraph, setOtEduAgeOriGraph] = useState([]);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [isOtGraphLoading, setIsOtGraphLoading] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  const getTableData = async () => {
    setIsTableLoading(true);
    const parData = {};
    parData.page = page;
    // parData.limit = 25;

    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setIsTableLoading(false);
      setTableData(data?.data?.cost_centers);

      if (data?.data?.hasNextPage) {
        getTableNextData(data?.data?.nextPage);
      }
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };

  const getTableNextData = async (pageIn) => {
    const parData = {};
    // parData.limit = 500;
    parData.page = pageIn;

    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);

      setTableData((prevData) => [
        ...prevData,
        ...(data?.data?.cost_centers || []),
      ]);

      if (data?.data?.hasNextPage) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        // setTimeout(() => {
        getTableNextData(data?.data?.nextPage);
        // }, 1000);
      }
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };

  const fetchDataRecursive = async (
    currentPage = page,
    initialCall = true,
    searchKey,
    limit,
    cancelToken,
    buisnessCategory
  ) => {
    // try {
    if (initialCall) {
      setIsTableLoading(true);
    }

    const parData = {
      page: currentPage,
      businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
    };

    if (searchKey) {
      parData.searchKeyword = searchKey;
    }

    if (limit) {
      parData.limit = limit;
    }

    if (buisnessCategory) {
      parData.businessUnit = buisnessCategory;
    }

    // else {
    //   parData.businessUnit = "";
    // }
    const encryptData = dataEnCrypt(parData);
    const response = await axios.post(
      `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
      { payloadData: encryptData },
      { cancelToken: cancelToken }
    );
    const responseData = decryptedFunction(response?.data?.responseData);
    const costCenters = responseData?.data?.cost_centers || [];

    if (searchKey || buisnessCategory || isBuisnessUnitSet !== null) {
      setTableData(costCenters);
    } else {
      setTableData((prevData) => [...prevData, ...costCenters]);
    }

    if (initialCall) {
      setIsTableLoading(false);
    }

    if (response?.data?.data?.hasNextPage) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      await fetchDataRecursive(
        response?.data?.data?.nextPage,
        false,
        null,
        null,
        null,
        buisnessCategory
      );
    }
    // } catch (error) {
    //   setIsTableLoading(false);
    //   if (axios.isCancel(error)) {
    //     console.log("Request canceled:", error.message);
    //   } else {
    //     console.error("Error fetching data:", error);
    //   }
    // }
  };

  useEffect(() => {
    if (didMountRef.current) {
      return;
    }

    const fetchData = async () => {
      try {
        // Run all other functions concurrently
        await Promise.all([
          getMetaData(),
          getRefreshSchedule(),
          getBuisnesUnit(),
          getVpData(),
          getExecutiveData(),
          getDirectorData(),
          getManagerData(),
          getCostCenterList(),
          actualAndTargetGraphData(),
          OtEduAgeOriGraphData(),
          ppfiscalYearList(),
        ]);
        setInitialLoadDone(true);
        // Once all are completed, call ppfiscalYearList()
      } catch (error) {
        console.error("Error during data fetching:", error);
      }
    };

    fetchData();

    didMountRef.current = true;
  }, []);

  const sortingHandle = (key, bindname = null) => {
    const dataToSort = bindname ? get(tableData, bindname, []) : tableData;
    const sortdata = getTableRecordWithSorting(dataToSort, key, sortType);
    setSortType(sortType === "asc" ? "desc" : "asc");
    activityLog(SHIFT_FORM.TABLE_NAMES[key] ?? "", sortType);

    if (bindname) {
      setTableData({ ...tableData, [bindname]: sortdata });
    } else {
      setTableData(sortdata);
    }

    setsortName(key);
  };

  const handleDoubleExceptional = (val) => {
    navigate(`/activedailymanagement/adm-summary/${val?.cost_center_slug}`);
  };

  const getMetaData = async () => {
    try {
      const param = {
        application_id: 103,
      };
      const encryptData = dataEnCrypt(param);
      const response = await axios.post(`${API_CALL_URL_PRIVATE}/metaData`, {
        payloadData: encryptData,
      });
      const data = decryptedFunction(response?.data?.responseData);
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = metaData?.meta_title || "Biweekly Reporting";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);

  const getRefreshSchedule = async (refreshType) => {
    try {
      const parData = {};
      if (refreshType) {
        parData.clicked = 1;
      }

      const encryptData = dataEnCrypt(parData);

      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/getFooterData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setScheduleData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSearchInput = async (key) => {
    //  setIsTableLoading(true);
    try {
      if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      const newCancelToken = Axios.CancelToken.source();
      setCancelToken(newCancelToken);

      if (key !== "") {
        fetchDataRecursive(page, true, key, 1000, newCancelToken.token);
      } else {
        fetchDataRecursive(page, true, key, null, newCancelToken.token);
      }

      // const { data } = await axios.post(
      //   `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
      //   parData
      // );

      // setTableData(data.data?.cost_centers);

      // if (data?.data?.hasNextPage) {
      //   getTableNextData(data?.data?.nextPage);
      // }
      // setIsTableLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleOpenThree = () => {
    setIsDropdownOpenThree((prevIsOpen) => !prevIsOpen);
  };

  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 150, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
    option: (provided, state) => ({
      ...provided,
      ...(state.isDisabled && {
        backgroundColor: themeMode.theme === "DARK" ? "#444" : "#f5f5f5", // Background for disabled state
        borderColor: themeMode.theme === "DARK" ? "#666" : "#ccc", // Border for disabled state
        pointerEvents: "not-allowed", // Disable interaction
        cursor: "not-allowed",
      }),
    }),
  };

  const getBuisnesUnit = async (ccn) => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/BusinessUnitDescriptionList`
      );
      const data = decryptedFunction(resp?.data?.responseData);

      setBuisnessData(
        data?.data?.data?.map((val) => {
          return {
            label: val,
            value: val,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const buissnessUnitUpdate = async (value) => {
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/businessUnitDescriptionUpdate`,
        {
          business_unit_description: value,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isBuisnessUnitSet !== null) {
      setDropdownValueThree({
        label: isBuisnessUnitSet,
        value: isBuisnessUnitSet,
      });
    }
  }, [isBuisnessUnitSet]);

  useEffect(() => {
    if (mounted) {
      fetchDataRecursive();
    } else {
      setMounted(true);
    }
  }, [buisnessHeader]);

  const activityLog = async (table_column, sortType) => {
    try {
      const paramData = {
        activity_id: 47,
        page_type: "Active Daily Management",
        application_id: 105,
        table_name: "Active Daily Management",
        table_column: table_column,
        sort_order: sortType === "asc" ? "Ascending" : "Descending",
        searchKeyword: searchBoxValue,
        businessUnit: isBuisnessUnitSet,
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activity-log/create`,
        { payloadData: encryptData }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const toggleOpen = () => {
    setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
  };

  const toggleOpenTwo = () => {
    setIsDropdownOpentwo((prevIsOpen) => !prevIsOpen);
  };

  const toggleOpenFour = () => {
    setIsDropdownOpenFour((prevIsOpen) => !prevIsOpen);
  };
  const toggleOpenFive = () => {
    setIsDropdownOpenFive((prevIsOpen) => !prevIsOpen);
  };
  const toggleOpenSix = () => {
    setIsDropdownOpenSix((prevIsOpen) => !prevIsOpen);
  };
  const toggleOpenSeven = () => {
    setIsDropdownOpenSeven((prevIsOpen) => !prevIsOpen);
  };

  const toggleOpenEight = () => {
    setIsDropdownOpenEight((prevIsOpen) => !prevIsOpen);
  };

  const getManagerData = async () => {
    const parData = {};
    parData.businessUnit = isBuisnessUnitSet !== null ? isBuisnessUnitSet : "";
    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/manaagerList`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);

      setManagerList(
        data?.data?.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getDirectorData = async () => {
    const parData = {};
    parData.businessUnit = isBuisnessUnitSet !== null ? isBuisnessUnitSet : "";
    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/directorList`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);

      setDirectorList(
        data?.data?.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getVpData = async () => {
    const parData = {};
    parData.businessUnit = isBuisnessUnitSet !== null ? isBuisnessUnitSet : "";
    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/vpList`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);

      setVpList(
        data?.data?.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
      );

      //  const matchingCostCenter = data?.data?.payPeriodEndDates[0];
    } catch (err) {
      console.log(err);
    }
  };

  const getExecutiveData = async () => {
    const parData = {};
    parData.businessUnit = isBuisnessUnitSet !== null ? isBuisnessUnitSet : "";
    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/executiveList`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);

      setExecutiveList(
        data?.data?.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
      );

      //  const matchingCostCenter = data?.data?.payPeriodEndDates[0];
    } catch (err) {
      console.log(err);
    }
  };

  const getCostCenterList = async (ccn) => {
    try {
      const paramData = {
        businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/costCentersList`,
        { payloadData: encryptData }
      );

      const data = decryptedFunction(response?.data?.responseData);

      setCostCenterList(
        data?.data?.costCenterResponse.map((item) => {
          return {
            label: `${item.cost_center_number} - ${capitalizeAllLetters(
              item.cost_center_name
            )}`,
            value: item.cost_center_number,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const ppfiscalYearList = async () => {
    //  setIsGraphLoading(true);
    //  setIsOtGraphLoading(true);
    try {
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/ppfiscalYearList`
      );

      const data = decryptedFunction(response?.data?.responseData);

      setYearDateList(
        data.data?.map((val) => {
          return {
            label: val,
            value: val,
          };
        })
      );

      setDropdownValueThree({
        label: data?.data[0],
        value: data?.data[0],
      });

      if (data?.data) {
        ppfiscalDateList(data?.data[0]);
      }

      return data?.data[0] || "";
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const ppfiscalDateList = async (ppYear, isParam) => {
    try {
      const parData = {
        fiscal_year: ppYear,
      };
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/fiscalYearPayPeriodDates`,
        { payloadData: encryptData }
      );
      console.log("yut", response);

       const responseData = decryptedFunction(response?.data?.responseData);
       const data = responseData?.data?.payPeriodEndDates;

      setPayperiodDateList(
        data?.map((val) => {
          return {
            label: val?.display_date,
            value: val?.payload_date,
          };
        })
      );

      const startDate = data?.at(0);
      const endDate = data?.at(-1);

      setStartDateValue({
        label: startDate?.display_date,
        value: startDate?.payload_date,
      });

      setEndDateValue({
        label: endDate?.display_date,
        value: endDate?.payload_date,
      });

      if (isParam) {
        actualAndTargetGraphData(
          startDate?.payload_date,
          endDate?.payload_date
        );
        OtEduAgeOriGraphData(startDate?.payload_date, endDate?.payload_date);
      }

      return data?.data[0] || "";
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const actualAndTargetGraphData = async (ppStartDate, ppEndDate) => {
    setIsGraphLoading(true);
    const parData = {
      cost_center_number: dropdownValue?.value,
      start_date: ppStartDate ? ppStartDate : startDateValue?.value,
      end_date: ppEndDate ? ppEndDate : endDateValue?.value,
      businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
      executive: executiveValue?.value,
      director: directorValue?.value,
      manager: managerValue?.value,
      vp: vpValue?.value,
    };

    const encryptData = dataEnCrypt(parData);

    try {
      const url = `${API_CALL_URL_PRIVATE}/biweeklyReporting/fteTrend/actualAndTargetWFGraphData`;
      const response = await axios.post(url, {
        payloadData: encryptData,
      });
      const data = decryptedFunction(response?.data?.responseData);
      console.log("yup", data?.data);

      setActualTargetGraph(data?.data);
      setIsGraphLoading(false);
      // setGraphMaxData(data.data.graph_max_val);
    } catch (error) {
      console.log(error);
    } finally {
      // setIsGraphLoading(false);
    }
  };

  const OtEduAgeOriGraphData = async (ppStartDate, ppEndDate) => {
    setIsOtGraphLoading(true);
    const parData = {
      cost_center_number: dropdownValue?.value,
      start_date: ppStartDate ? ppStartDate : startDateValue?.value,
      end_date: ppEndDate ? ppEndDate : endDateValue?.value,
      businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
      executive: executiveValue?.value,
      director: directorValue?.value,
      manager: managerValue?.value,
      vp: vpValue?.value,
    };

    const encryptData = dataEnCrypt(parData);

    try {
      const url = `${API_CALL_URL_PRIVATE}/biweeklyReporting/fteTrend/OtEduAgeOriGraphData`;
      const response = await axios.post(url, {
        payloadData: encryptData,
      });
      const data = decryptedFunction(response?.data?.responseData);
      console.log("yup", data?.data);

      setOtEduAgeOriGraph(data?.data);
      setIsOtGraphLoading(false);
      // setGraphMaxData(data.data.graph_max_val);
    } catch (error) {
      console.log(error);
    } finally {
      // setIsGraphLoading(false);
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (initialLoadDone) {
      actualAndTargetGraphData();
      OtEduAgeOriGraphData();
    }
  }, [dropdownValue, executiveValue, vpValue, directorValue]);

  const isStartDateDisabled = (option) => {
    if (!endDateValue) return false;
    return (
      new Date(option.value.split("-").reverse().join("-")) >
      new Date(endDateValue.value.split("-").reverse().join("-"))
    );
  };

  const isEndDateDisabled = (option) => {
    if (!startDateValue) return false;
    return (
      new Date(option.value.split("-").reverse().join("-")) <
      new Date(startDateValue.value.split("-").reverse().join("-"))
    );
  };

  // Custom styles for disabled options
  const customStyles = {
    option: (provided, state) => ({
      ...provided,

      ...(state.isDisabled && {
        backgroundColor: themeMode.theme === "DARK" ? "#444" : "#f5f5f5", // Background for disabled state
        borderColor: themeMode.theme === "DARK" ? "#666" : "#ccc", // Border for disabled state
        pointerEvents: "not-allowed", // Disable interaction
      }),
      //  color: state.isDisabled ? "#ccc" : "#000",
      //  backgroundColor: themeMode.theme === "DARK" ? "#444" : "#f5f5f5",
      //  cursor: state.isDisabled ? "not-allowed" : "pointer",
    }),
  };

  console.log("payperiodDateList", payperiodDateList);

  return (
    <PageLayout>
      <div className="activedailymanagement-s">
        <Row className="gx-2  mt-2 mb-2 align-items-center">
          <Col md={{ span: 1 }}>
            <Link
              to={`/biweekly`}
              className="f-14 white-text-dark-mode"
              // onClick={() => {
              //     dispatch(setUpdateDate(""));
              //     dispatch(setUpdateIndex(""));
              // }}
            >
              <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
            </Link>
          </Col>
          <Col md={{ span: 10 }}>
            <div className="d-flex mobile-flex-wrap justify-content-center align-items-center Bi_Su">
              {/* <div>
                <h3 className="text-center color-black m-0">
                  Biweekly Reporting -
                </h3>
              </div> */}

              <div
                className={`custom-select-v ${
                  !isEmpty(dropdownValue) ? "cap lt position-relative" : ""
                }`}
              >
                <CustomDropdown
                  isDropdownOpen={isDropdownOpen}
                  onClose={toggleOpen}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpen}
                      isSelected={isDropdownOpen}
                    >
                      {dropdownValue ? dropdownValue.label : "Cost Center"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpen}
                    onChange={(e) => {
                      toggleOpen();
                      setDropdownValue(e);
                      getTableData(e?.value, dropdownValueTwo?.value);
                    }}
                    options={costCenterList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={dropdownValue}
                  />
                </CustomDropdown>
                {!isEmpty(dropdownValue) && (
                  <span
                    onClick={() => {
                      setDropdownValue("");
                    }}
                    className="custom-cross"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    x
                  </span>
                )}
              </div>

              <div
                className={`custom-select-v ${
                  !isEmpty(executiveValue) ? "cap lt position-relative" : ""
                }`}
              >
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenfive}
                  onClose={toggleOpenFive}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenFive}
                      isSelected={isDropdownOpenfive}
                    >
                      {executiveValue ? executiveValue.label : "Executive"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenfive}
                    onChange={(e) => {
                      toggleOpenFive();
                      setExecutiveValue(e);
                      // setDropdownValue(e);
                      // getTableData(e?.value, dropdownValueFive?.value);
                    }}
                    options={executiveList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={"Executive"}
                  />
                </CustomDropdown>
                {!isEmpty(executiveValue) && (
                  <span
                    onClick={() => {
                      setExecutiveValue("");
                    }}
                    className="custom-cross"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    x
                  </span>
                )}
              </div>

              <div
                className={`custom-select-v ${
                  !isEmpty(vpValue) ? "cap lt position-relative" : ""
                }`}
              >
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenfour}
                  onClose={toggleOpenFour}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenFour}
                      isSelected={isDropdownOpenfour}
                    >
                      {vpValue ? vpValue.label : "VP"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenfour}
                    onChange={(e) => {
                      toggleOpenFour();
                      setVpValue(e);
                      // setDropdownValue(e);
                      // getTableData(e?.value, dropdownValueFour?.value);
                    }}
                    options={vpList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={"VP"}
                  />
                </CustomDropdown>
                {!isEmpty(vpValue) && (
                  <span
                    onClick={() => {
                      setVpValue("");
                    }}
                    className="custom-cross"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    x
                  </span>
                )}
              </div>

              <div
                className={`custom-select-v ${
                  !isEmpty(directorValue) ? "cap lt position-relative" : ""
                }`}
              >
                <CustomDropdown
                  isDropdownOpen={isDropdownOpensix}
                  onClose={toggleOpenSix}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenSix}
                      isSelected={isDropdownOpensix}
                    >
                      {directorValue ? directorValue.label : "Director"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpensix}
                    onChange={(e) => {
                      toggleOpenSix();
                      setDirectorValue(e);
                    }}
                    options={directorList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={"Director"}
                  />
                </CustomDropdown>
                {!isEmpty(directorValue) && (
                  <span
                    onClick={() => {
                      setDirectorValue("");
                    }}
                    className="custom-cross"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    x
                  </span>
                )}
              </div>

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenthree}
                  onClose={toggleOpenThree}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenThree}
                      isSelected={isDropdownOpenthree}
                    >
                      {dropdownValueThree ? dropdownValueThree.label : "Year"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenthree}
                    options={yearDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenThree();
                      setDropdownValueThree(e);
                      ppfiscalDateList(e?.value, true);
                    }}
                    value={dropdownValueThree}
                  />
                </CustomDropdown>
              </div>

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenseven}
                  onClose={toggleOpenSeven}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenSeven}
                      isSelected={isDropdownOpenseven}
                    >
                      {startDateValue ? startDateValue.label : "Start Date"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenseven}
                    options={payperiodDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    isOptionDisabled={isStartDateDisabled}
                    onChange={(e) => {
                      toggleOpenSeven();
                      setStartDateValue(e);
                      actualAndTargetGraphData(e?.value, endDateValue?.value);
                      OtEduAgeOriGraphData(e?.value, endDateValue?.value);
                    }}
                    value={startDateValue}
                  />
                </CustomDropdown>
              </div>

              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpeneight}
                  onClose={toggleOpenEight}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenEight}
                      isSelected={isDropdownOpeneight}
                    >
                      {endDateValue ? endDateValue.label : "End Date"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpeneight}
                    options={payperiodDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    isOptionDisabled={isEndDateDisabled}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenEight();
                      setEndDateValue(e);
                      actualAndTargetGraphData(startDateValue?.value, e?.value);
                      OtEduAgeOriGraphData(startDateValue?.value, e?.value);
                    }}
                    value={endDateValue}
                  />
                </CustomDropdown>
              </div>
              {/* <div className="d-flex timeline">
                <div className="col flex-grow-0 me-2" id="pills-tabContent">
                  <div className="col-md-12">
                    <DatePicker
                      placeholderText="Start Date"
                      showIcon
                      dateFormat="MM/dd/yyyy"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      // maxDate={formattedDate}
                      // dateFormat="MM/dd/Y"
                      // className={active == "datepicker" ? "active" : ""}
                      // selected={startDate == "" ? time : startDate}
                      // onChange={(date) => datepickerHandler(date)}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex timeline">
                <div className="col flex-grow-0 me-2" id="pills-tabContent">
                  <div className="col-md-12">
                    <DatePicker
                      placeholderText="End Date"
                      showIcon
                      selected={endDate}
                      minDate={startDate || new Date()}
                      onChange={(date) => setEndDate(date)}
                      // maxDate={formattedDate}
                      // dateFormat="MM/dd/Y"
                      // className={active == "datepicker" ? "active" : ""}
                      // selected={startDate == "" ? time : startDate}
                      // onChange={(date) => datepickerHandler(date)}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </Col>
          <Col md={12} className="text-end mobile-center mobile-my-2"></Col>

          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row>

        <Row className="gx-2 mt-3">
          <Col md={12} className="mt-2 h-680 bg-white">
            <div className="bg-white p-3  text-center height-50persent">
              <div class="f-16 text-center mb-minus-10 font-w-500 mb-2">
                {dropdownValue ? dropdownValue.label : "All Cost Center"} :
                Actual And Target Worked FTE Trends
              </div>
              <div className="">
                {/* <img src={LineChart} alt="" style={{margin: "0 auto"}}/> */}
                {isGraphLoading ? (
                  <GraphLoader themeMode={themeMode} />
                ) : (
                  <BiweeklyLineChart
                    data={actualTargetGraph}
                    color={"#458FFF"}
                  />
                )}
              </div>
            </div>
            <div className="bg-white p-3 pt-0  text-center height-50persent">
              {/* <img src={LineChart} alt="" style={{margin: "0 auto"}}/> */}
              {isOtGraphLoading ? (
                <GraphLoader themeMode={themeMode} />
              ) : (
                <BiweeklyLineChart2 data={otEduAgeOriGraph} color={"#458FFF"} />
              )}
            </div>
          </Col>
          <Col md={12} className="ft-text mt-2 mb-2 px-1 ft-text">
            <div className="row  f-14 d-flex justify-content-between gx-2 m-0">
              <div className="col-12 text-end text-mobile-center bg-white">
                <span
                  className="d-inline-block p-2  cursor-pointer"
                  onClick={() => {
                    getRefreshSchedule(true);
                    setFooterShow(true);
                  }}
                >
                  {scheduleData?.msg1}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
}

export default FTETrends;
