import "./index.css";
import React from "react";
import { useEffect, useContext, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import InfoModal from "../../../components/shared/StopLossChart/howtouse";
import FooterModal from "../../../components/ActiveDailyManagementComponents/shared/footerModal";
import ContentLoader from "react-content-loader";
import PageLayout from "../../../layouts/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get, isEmpty, reverse } from "lodash";
import { API_CALL_URL } from "../../../constants";
import { API_CALL_URL_PRIVATE } from "../../../constants";
import axios from "../../../axios";
import Axios from "axios";
import { getTableRecordWithSorting } from "../../../services";
import {
  capitalizeAllLetters,
  formatNumberWithFraction,
} from "../../../utils/helper";
import {
  setUpdateDate,
  setUpdateIndex,
  setUpdateStack,
  setExceptionIndex,
} from "../../../store/actions/Compensate";
import DescIcon from "../../../assets/img/desc.png";
import AscIcon from "../../../assets/img/asc.png";
import { ThemeContext } from "../../../providers/ThemeProvider";
import PsLogo from "../../../assets/img/pslogo.png";
import ADMImage from "../../../assets/img/biweekly_report.png";
import Button from "@atlaskit/button";

import SHIFT_FORM from "../../../constants/shiftForm";
import {
  CustomDropdown,
  DropdownIndicator,
  ChevronDown,
} from "../../../Styled/DropdownStyle";
import ButtonKit from "@atlaskit/button";
import Select from "react-select";
import { decryptedFunction, dataEnCrypt } from "../../../utils/helper";
import LineChart from "../../../components/shared/StopLossChart/lineChart";
import BarChart from "../../../components/shared/StopLossChart/barChart";
import { CgAdd } from "react-icons/cg";
import { CgRemove } from "react-icons/cg";

const HeaderLoader = () => (
  <ContentLoader viewBox="0 0 280 17">
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="17" />
  </ContentLoader>
);

const HeaderLoaderText = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 10"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="10" />
  </ContentLoader>
);

const TableLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 8"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="8" />
  </ContentLoader>
);
const GraphLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 90"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="150" />
  </ContentLoader>
);

const LinkLoader = ({ themeMode }) => (
  <div style={{ width: 40, height: 20 }}>
    <ContentLoader
      viewBox="0 0 80 50"
      backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
      foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
    </ContentLoader>
  </div>
);
const options = [
  { value: 'option1', label: 'Option 1' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' },
];

function ExcutiveFTEReport({ history }) {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const isFirstRender = useRef(true);
  const buisnessHeader = useSelector(
    (state) => state.compensate.buisnessHeader
  );
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [modalShow, setModalShow] = React.useState(false);
  const [footerShow, setFooterShow] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [totalvalue, setTotalvalue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [sortType, setSortType] = useState("asc");
  const [sortName, setsortName] = useState("");
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [metaData, setMetaData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [searchBoxValue, setSearchBoxValue] = useState("");

  const [vpList, setVpList] = useState([]);
  const [executiveList, setExecutiveList] = useState([]);
  const [directorList, setDirectorList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [executiveValue, setExecutiveValue] = useState(null);
  const [vpValue, setVpValue] = useState(null);
  const [directorValue, setDirectorValue] = useState(null);
  const [managerValue, setManagerValue] = useState(null);
  const [yearDateList, setYearDateList] = useState([]);
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  const [page, setPage] = useState(1);
  const [cancelToken, setCancelToken] = useState(null);
  const [buisnessData, setBuisnessData] = useState({});
  const [mounted, setMounted] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(undefined);

  const [isDropdownOpentwo, setIsDropdownOpentwo] = useState(false);
  const [dropdownValueTwo, setDropdownValueTwo] = useState(undefined);

  const [isDropdownOpenthree, setIsDropdownOpenThree] = useState(false);
  const [dropdownValueThree, setDropdownValueThree] = useState(undefined);

  const [isDropdownOpenfour, setIsDropdownOpenFour] = useState(false);
  const [dropdownValueFour, setDropdownValueFour] = useState(undefined);

  const [isDropdownOpenfive, setIsDropdownOpenFive] = useState(false);
  const [dropdownValueFive, setDropdownValueFive] = useState(undefined);

  const [isDropdownOpensix, setIsDropdownOpenSix] = useState(false);
  const [dropdownValueSix, setDropdownValueSix] = useState(undefined);

  const [isDropdownOpenseven, setIsDropdownOpenSeven] = useState(false);
  const [dropdownValueSeven, setDropdownValueSeven] = useState(undefined);

  const [selectedOption, setSelectedOption] = useState(null);

  const [costCenterList, setCostCenterList] = useState([
    {
      label: "100061102 - 4 WEST",
      value: "100061102 - 4 WEST",
    },
  ]);


  const [filterDateList, setFilterDateList] = useState([
    {
      label: "08/10/2024",
      value: "08/10/2024",
    },
  ]);
  const [payperiodDateList, setPayperiodDateList] = useState([]);
  const [startDateValue, setStartDateValue] = useState(null);
  const [executiveFteData, setExecutiveFteData] = useState([]);
  const [executiveFteResult, setExecutiveFteResult] = useState([]);

  // State to track visibility
  const [isVisible, setIsVisible] = useState(false);
  const [visibility, setVisibility] = useState({});

  // Toggle visibility
const toggleDiv = (id) => {
  setVisibility((prevState) => ({
    ...prevState,
    [id]: !prevState[id],
  }));
};

  const getTableData = async () => {
    setIsTableLoading(true);
    const parData = {};
    parData.page = page;
    // parData.limit = 25;

    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setIsTableLoading(false);
      setTableData(data?.data?.cost_centers);

      if (data?.data?.hasNextPage) {
        getTableNextData(data?.data?.nextPage);
      }
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };

  const getTableNextData = async (pageIn) => {
    const parData = {};
    // parData.limit = 500;
    parData.page = pageIn;

    try {
      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);

      setTableData((prevData) => [
        ...prevData,
        ...(data?.data?.cost_centers || []),
      ]);

      if (data?.data?.hasNextPage) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        // setTimeout(() => {
        getTableNextData(data?.data?.nextPage);
        // }, 1000);
      }
    } catch (error) {
      setIsTableLoading(false);
      console.log(error);
    }
  };

  const fetchDataRecursive = async (
    currentPage = page,
    initialCall = true,
    searchKey,
    limit,
    cancelToken,
    buisnessCategory
  ) => {
    // try {
    if (initialCall) {
      setIsTableLoading(true);
    }

    const parData = {
      page: currentPage,
      businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
    };

    if (searchKey) {
      parData.searchKeyword = searchKey;
    }

    if (limit) {
      parData.limit = limit;
    }

    if (buisnessCategory) {
      parData.businessUnit = buisnessCategory;
    }

    // else {
    //   parData.businessUnit = "";
    // }
    const encryptData = dataEnCrypt(parData);
    const response = await axios.post(
      `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
      { payloadData: encryptData },
      { cancelToken: cancelToken }
    );
    const responseData = decryptedFunction(response?.data?.responseData);
    const costCenters = responseData?.data?.cost_centers || [];

    if (searchKey || buisnessCategory || isBuisnessUnitSet !== null) {
      setTableData(costCenters);
    } else {
      setTableData((prevData) => [...prevData, ...costCenters]);
    }

    if (initialCall) {
      setIsTableLoading(false);
    }

    if (response?.data?.data?.hasNextPage) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      await fetchDataRecursive(
        response?.data?.data?.nextPage,
        false,
        null,
        null,
        null,
        buisnessCategory
      );
    }
    // } catch (error) {
    //   setIsTableLoading(false);
    //   if (axios.isCancel(error)) {
    //     console.log("Request canceled:", error.message);
    //   } else {
    //     console.error("Error fetching data:", error);
    //   }
    // }
  };

  const getHeadData = async () => {
    setIsLoading(true);

    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/activeDailyManagement/getheaderData`
      );
      const data = decryptedFunction(resp?.data?.responseData);
      setIsLoading(false);
      setHeadData(data.data);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getCostCenterList = async (ccn) => {
    try {
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/costCentersLit`
      );
      const data = decryptedFunction(response?.data?.responseData);

      // setCostCenterList(
      //   data?.data?.costCenterResponse.map((item) => {
      //     return {
      //       label: `${item.cost_center_number} - ${capitalizeAllLetters(
      //         item.cost_center_name
      //       )}`,
      //       value: item.cost_center_slug,
      //     };
      //   })
      // );
    } catch (err) {
      console.log(err);
    }
  };



    useEffect(() => {

      const fetchData = async () => {
        try {
          // Run all other functions concurrently
          await Promise.all([
          getMetaData(),
          getRefreshSchedule(),
          getBuisnesUnit(),
          getVpData(),
          getExecutiveData(),
          getDirectorData(),
          ppfiscalYearList(),
          ]);

          setInitialLoadDone(true);
        } catch (error) {
          console.error("Error during data fetching:", error);
        }
      };

      fetchData();
    }, []);

  const sortingHandle = (key, bindname = null) => {

    const dataToSort = bindname
      ? get(executiveFteData, bindname, [])
      : executiveFteData;
    const sortdata = getTableRecordWithSorting(dataToSort, key, sortType);
    setSortType(sortType === "asc" ? "desc" : "asc");
    activityLog(SHIFT_FORM.TABLE_NAMES[key] ?? "", sortType);


    if (bindname) {
      setExecutiveFteData({ ...executiveFteData, [bindname]: sortdata });
    } else {
      setExecutiveFteData(sortdata);
    }

    setsortName(key);
  };

  console.log("upo", executiveFteData);
  

  const emptyActiveData = () => {
    getTableData();
    getHeadData();
    setSelectedRow(null);
    dispatch(setUpdateDate(""));
    dispatch(setUpdateIndex(""));
    dispatch(setExceptionIndex(""));
    dispatch(setUpdateStack(""));
  };

  const handleDoubleExceptional = (val) => {
    navigate(`/activedailymanagement/adm-summary/${val?.cost_center_slug}`);
  };

  const getMetaData = async () => {
    try {
      const param = {
        application_id: 103,
      };
      const encryptData = dataEnCrypt(param);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/metaData`,
        {
          payloadData: encryptData,
        }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = metaData?.meta_title || "Active Daily Management";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);

  const getRefreshSchedule = async (refreshType) => {
    try {
      const parData = {};
      if (refreshType) {
        parData.clicked = 1;
      }

      const encryptData = dataEnCrypt(parData);

      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/fileRefreshSchedule`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setScheduleData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSearchInput = async (key) => {
    //  setIsTableLoading(true);
    try {
      if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      const newCancelToken = Axios.CancelToken.source();
      setCancelToken(newCancelToken);

      if (key !== "") {
        fetchDataRecursive(page, true, key, 1000, newCancelToken.token);
      } else {
        fetchDataRecursive(page, true, key, null, newCancelToken.token);
      }

      // const { data } = await axios.post(
      //   `${API_CALL_URL_PRIVATE}/activeDailyManagement/getData`,
      //   parData
      // );

      // setTableData(data.data?.cost_centers);

      // if (data?.data?.hasNextPage) {
      //   getTableNextData(data?.data?.nextPage);
      // }
      // setIsTableLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

 

  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 150, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  };

  const getBuisnesUnit = async (ccn) => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/BusinessUnitDescriptionList`
      );
      const data = decryptedFunction(resp?.data?.responseData);

      setBuisnessData(
        data?.data?.data?.map((val) => {
          return {
            label: val,
            value: val,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };


  const buissnessUnitUpdate = async (value) => {
    try {
      const { data } = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/businessUnitDescriptionUpdate`,
        {
          business_unit_description: value,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isBuisnessUnitSet !== null) {
      setDropdownValueThree({
        label: isBuisnessUnitSet,
        value: isBuisnessUnitSet,
      });
    }

  }, [isBuisnessUnitSet]);


  useEffect(() => {
    if (mounted) {
      fetchDataRecursive();
    } else {
      setMounted(true);
    }
  }, [buisnessHeader]);

  const activityLog = async (table_column, sortType) => {
    try {
      const paramData = {
        activity_id: 47,
        page_type: "Active Daily Management",
        application_id: 105,
        table_name: "Active Daily Management",
        table_column: table_column,
        sort_order: sortType === "asc" ? "Ascending" : "Descending",
        searchKeyword: searchBoxValue,
        businessUnit: isBuisnessUnitSet,
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activity-log/create`,
        { payloadData: encryptData }
      );
    } catch (err) {
      console.log(err);
    }
  };

    const toggleOpen = () => {
      setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
    };

    const toggleOpenTwo = () => {
      setIsDropdownOpentwo((prevIsOpen) => !prevIsOpen);
    };

    const toggleOpenThree = () => {
        setIsDropdownOpenThree((prevIsOpen) => !prevIsOpen);
      };

      const toggleOpenFour = () => {
        setIsDropdownOpenFour((prevIsOpen) => !prevIsOpen);
      };

      const toggleOpenFive = () => {
        setIsDropdownOpenFive((prevIsOpen) => !prevIsOpen);
      };

      const toggleOpenSix = () => {
        setIsDropdownOpenSix((prevIsOpen) => !prevIsOpen);
      };

      const toggleOpenSeven = () => {
        setIsDropdownOpenSeven((prevIsOpen) => !prevIsOpen);
      };

    useEffect(() => {}, [isDropdownOpen, dropdownValue]);



      const getDirectorData = async () => {
        const parData = {};
        parData.businessUnit =
          isBuisnessUnitSet !== null ? isBuisnessUnitSet : "";
        try {
          const encryptData = dataEnCrypt(parData);
          const response = await axios.post(
            `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/directorList`,
            { payloadData: encryptData }
          );
          const data = decryptedFunction(response?.data?.responseData);

          setDirectorList(
            data?.data?.map((item) => {
              return {
                label: item,
                value: item,
              };
            })
          );
        } catch (err) {
          console.log(err);
        }
      };

      const getVpData = async () => {
        const parData = {};
        parData.businessUnit =
          isBuisnessUnitSet !== null ? isBuisnessUnitSet : "";
        try {
          const encryptData = dataEnCrypt(parData);
          const response = await axios.post(
            `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/vpList`,
            { payloadData: encryptData }
          );
          const data = decryptedFunction(response?.data?.responseData);

          setVpList(
            data?.data?.map((item) => {
              return {
                label: item,
                value: item,
              };
            })
          );

          //  const matchingCostCenter = data?.data?.payPeriodEndDates[0];
        } catch (err) {
          console.log(err);
        }
      };

      const getExecutiveData = async () => {
        const parData = {};
        parData.businessUnit =
          isBuisnessUnitSet !== null ? isBuisnessUnitSet : "";
        try {
          const encryptData = dataEnCrypt(parData);
          const response = await axios.post(
            `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/executiveList`,
            { payloadData: encryptData }
          );
          const data = decryptedFunction(response?.data?.responseData);

          setExecutiveList(
            data?.data?.map((item) => {
              return {
                label: item,
                value: item,
              };
            })
          );

          //  const matchingCostCenter = data?.data?.payPeriodEndDates[0];
        } catch (err) {
          console.log(err);
        }
      };

      const ppfiscalYearList = async () => {
        setIsTableLoading(true);
        try {
          const response = await axios.post(
            `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/ppfiscalYearList`
          );

          const data = decryptedFunction(response?.data);

          setYearDateList(
            data.data?.map((val) => {
              return {
                label: val,
                value: val,
              };
            })
          );

          setDropdownValueThree({
            label: data?.data[0],
            value: data?.data[0],
          });

          if (data?.data) {
            ppfiscalDateList(data?.data[0]);
          }

          return data?.data[0] || "";
        } catch (error) {
          console.error("Failed to fetch user data:", error);
        } finally {
          setIsLoading(false);
        }
      };


        const ppfiscalDateList = async (ppYear) => {
          try {
            const parData = {
              fiscal_year: ppYear,
              reverse_array: true,
            };
            const encryptData = dataEnCrypt(parData);
            const response = await axios.post(
              `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/fiscalYearPayPeriodDates`,
              { payloadData: encryptData }
            );

            const data = decryptedFunction(
              response?.data?.responseData?.data?.payPeriodEndDates
            );

            setPayperiodDateList(
              data?.map((val) => {
                return {
                  label: val?.display_date,
                  value: val?.payload_date,
                };
              })
            );

            const startDate = data?.at(0);

            setStartDateValue({
              label: startDate?.display_date,
              value: startDate?.payload_date,
            });
            executiveFteList(startDate?.payload_date, ppYear);
            return data?.data[0] || "";
          } catch (error) {
            console.error("Failed to fetch user data:", error);
          } finally {
            setIsLoading(false);
          }
        };


        const ppSingleFiscalDateList = async (ppYear) => {
          try {
            const parData = {
              fiscal_year: ppYear,
            };
            const encryptData = dataEnCrypt(parData);
            const response = await axios.post(
              `${API_CALL_URL_PRIVATE}/biweeklyReporting/biweeklyGeneral/fiscalYearPayPeriodDates`,
              { payloadData: encryptData }
            );

            const data = decryptedFunction(
              response?.data?.responseData?.data?.payPeriodEndDates
            );

            setPayperiodDateList(
              data?.map((val) => {
                return {
                  label: val?.display_date,
                  value: val?.payload_date,
                };
              })
            );

            // executiveFteList(startDate?.payload_date, ppYear);
            // return data?.data[0] || "";
          } catch (error) {
            console.error("Failed to fetch user data:", error);
          } finally {
            setIsLoading(false);
          }
        };

        const executiveFteList = async (ppStartDate, ppYear) => {
          setIsTableLoading(true);
          const parData = {
            fiscal_year: ppYear ? ppYear : dropdownValueThree?.value,
            payperiod_end_date: ppStartDate,
            businessUnit: isBuisnessUnitSet !== null ? isBuisnessUnitSet : "",
            executive: executiveValue?.value,
            director: directorValue?.value,
            manager: managerValue?.value,
            vp: vpValue?.value,
          };

          const encryptData = dataEnCrypt(parData);

          try {
            const url = `${API_CALL_URL_PRIVATE}/biweeklyReporting/executiveFteReport/getData`;
            const response = await axios.post(url, {
              payloadData: encryptData,
            });
            const data = decryptedFunction(response?.data?.responseData);
            console.log("yup", data?.data?.result);

            setExecutiveFteData(data?.data?.result);
            setExecutiveFteResult(data?.data?.grandTotalData[0]);
            // setGraphMaxData(data.data.graph_max_val);
             setIsTableLoading(false);
          } catch (error) {
              setIsTableLoading(false);
            console.log(error);
          } finally {
            // setIsGraphLoading(false);
          }
        };

        console.log("executiveFteData", executiveFteData);
        

        useEffect(() => {
          if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
          }

          if (initialLoadDone) {
            executiveFteList();
          }
        }, [
          executiveValue,
          vpValue,
          directorValue,
        ]);

  return (
    <PageLayout>
      <div className="activedailymanagement-s">
        {/* <Row className="gx-2 mt-0 align-items-center">
          <Col className="d-flex " md={{ span: 2 }}>
            
          </Col>
          <Col md={{ span: 8 }}>
            <Row className="mt-2 justify-content-center">
              <Col md={3}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
              <Col md={3}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
              <Col md={3}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
              <Col md={3}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </Col>
            </Row>
          </Col>
         

          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row> */}

        <Row className="gx-2  mt-2 mb-2 align-items-center">
          <Col md={{ span: 1 }}>
            <Link
              to={`/biweekly`}
              className="f-14 white-text-dark-mode"
              // onClick={() => {
              //     dispatch(setUpdateDate(""));
              //     dispatch(setUpdateIndex(""));
              // }}
            >
              <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
            </Link>
          </Col>
          <Col md={{ span: 9 }}>
            <div className="d-flex mobile-flex-wrap justify-content-center align-items-center Bi_Su">
              <div
                className={`custom-select-v ${
                  !isEmpty(startDateValue) ? "cap lt position-relative" : ""
                }`}
              >
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenseven}
                  onClose={toggleOpenSeven}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenSeven}
                      isSelected={isDropdownOpenseven}
                    >
                      {startDateValue ? startDateValue.label : "Select Date"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenseven}
                    options={payperiodDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenSeven();
                      executiveFteList(e?.value, startDateValue?.value);
                      setStartDateValue(e);
                    }}
                    value={startDateValue}
                  />
                </CustomDropdown>
                {!isEmpty(startDateValue) && (
                  <span
                    onClick={() => {
                      executiveFteList(null, dropdownValueThree?.value);
                      setStartDateValue("");
                    }}
                    className="custom-cross"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    x
                  </span>
                )}
              </div>
              <div className="custom-select-v">
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenthree}
                  onClose={toggleOpenThree}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenThree}
                      isSelected={isDropdownOpenthree}
                    >
                      {dropdownValueThree ? dropdownValueThree.label : "Year"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenthree}
                    options={yearDateList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    onChange={(e) => {
                      toggleOpenThree();
                      setDropdownValueThree(e);
                      ppSingleFiscalDateList(e?.value);
                      executiveFteList(null, e?.value);
                      setStartDateValue("");
                    }}
                    value={dropdownValueThree}
                  />
                </CustomDropdown>
              </div>

              <div
                className={`custom-select-v ${
                  !isEmpty(executiveValue) ? "cap lt position-relative" : ""
                }`}
              >
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenfive}
                  onClose={toggleOpenFive}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenFive}
                      isSelected={isDropdownOpenfive}
                    >
                      {executiveValue ? executiveValue.label : "Executive"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenfive}
                    onChange={(e) => {
                      toggleOpenFive();
                      setExecutiveValue(e);
                      // setDropdownValue(e);
                      // getTableData(e?.value, dropdownValueFive?.value);
                    }}
                    options={executiveList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={"Executive"}
                  />
                </CustomDropdown>
                {!isEmpty(executiveValue) && (
                  <span
                    onClick={() => {
                      setExecutiveValue("");
                    }}
                    className="custom-cross"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    x
                  </span>
                )}
              </div>

              <div
                className={`custom-select-v ${
                  !isEmpty(vpValue) ? "cap lt position-relative" : ""
                }`}
              >
                <CustomDropdown
                  isDropdownOpen={isDropdownOpenfour}
                  onClose={toggleOpenFour}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenFour}
                      isSelected={isDropdownOpenfour}
                    >
                      {vpValue ? vpValue.label : "VP"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpenfour}
                    onChange={(e) => {
                      toggleOpenFour();
                      setVpValue(e);
                      // setDropdownValue(e);
                      // getTableData(e?.value, dropdownValueFour?.value);
                    }}
                    options={vpList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={"VP"}
                  />
                </CustomDropdown>
                {!isEmpty(vpValue) && (
                  <span
                    onClick={() => {
                      setVpValue("");
                    }}
                    className="custom-cross"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    x
                  </span>
                )}
              </div>

              <div
                className={`custom-select-v ${
                  !isEmpty(directorValue) ? "cap lt position-relative" : ""
                }`}
              >
                <CustomDropdown
                  isDropdownOpen={isDropdownOpensix}
                  onClose={toggleOpenSix}
                  target={
                    <Button
                      iconAfter={<ChevronDown />}
                      onClick={toggleOpenSix}
                      isSelected={isDropdownOpensix}
                    >
                      {directorValue ? directorValue.label : "Director"}
                    </Button>
                  }
                >
                  <Select
                    autoFocus
                    backspaceRemovesValue={false}
                    components={{ DropdownIndicator, IndicatorSeparator: null }}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    menuIsOpen={isDropdownOpensix}
                    onChange={(e) => {
                      toggleOpenSix();
                      setDirectorValue(e);
                    }}
                    options={directorList}
                    placeholder="Search..."
                    styles={selectStyles}
                    tabSelectsValue={false}
                    value={"Director"}
                  />
                </CustomDropdown>
                {!isEmpty(directorValue) && (
                  <span
                    onClick={() => {
                      setDirectorValue("");
                    }}
                    className="custom-cross"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    x
                  </span>
                )}
              </div>
            </div>
          </Col>
          <Col md={2} className="text-end mobile-center mobile-my-2">
            {/* <small
              className="cursor-pointer border-btn f-12  green-b"
              onClick={() => {
                setModalShow(true)
                // activityLogbutton();
              }}
            >
             How to use this page?{" "}
            </small> */}
          </Col>
          <Col md={12} className="text-end mobile-center mobile-my-2"></Col>

          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row>

        <Row className="gx-2">
          <Col md={12} className="mt-2 h-750">
            <div className="bg-white p-3 h-100">
              {/* <div class="text-center mb-2"><div class="box-heading text-center">Stop Loss Report Rolling - 4</div></div> */}

              <Table
                responsive
                className="table activity-tabel mb-0 ind-table tb2   exceptions-table scroll-table first-t h-700"
              >
                <thead className="first-col-w">
                  <tr className="vertical-align-middle">
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("employee_name")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="text-center">Executive</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "employee_name" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th>
                      {isVisible && (
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="text-center">
                            Cost Center Number Name
                          </div>
                          <div className="ms-2">
                            {sortType === "asc" &&
                            sortName === "cost_center_number" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      )}
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("total_actual_worked_ftes")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="text-center">Actual Worked FTEs</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "total_actual_worked_ftes" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("total_target_worked_ftes")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="text-center">Target Worked FTEs</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "total_target_worked_ftes" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("total_worked_fte_variance")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="text-center">Variance</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "total_worked_fte_variance" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() =>
                        sortingHandle("total_worked_fte_variance_percentage")
                      }
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="text-center">Variance %</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName ===
                            "total_worked_fte_variance_percentage" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("total_actual_paid_ftes")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="text-center">Actual Paid FTEs</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "total_actual_paid_ftes" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("total_target_paid_ftes")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="text-center">Target Paid FTEs</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "total_target_paid_ftes" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("total_paid_fte_variance")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="text-center">Variance</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "total_paid_fte_variance" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() =>
                        sortingHandle("total_paid_fte_variance_percentage")
                      }
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="text-center">Variance %</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "total_paid_fte_variance_percentage" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("actual_pto_factor")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="text-center">Actual PTO Factor</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "actual_pto_factor" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                    <th
                      className="text-center cursor-pointer"
                      onClick={() => sortingHandle("target_pto_factor")}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="text-center">Target PTO Factor</div>
                        <div className="ms-2">
                          {sortType === "asc" &&
                          sortName === "target_pto_factor" ? (
                            <img src={AscIcon} alt="" />
                          ) : (
                            <img src={DescIcon} alt="" />
                          )}
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody className="ftr first-col-w">
                  {!isTableLoading ? (
                    executiveFteData?.length > 0 ? (
                      executiveFteData?.map((val, ind) => {
                        const isVisible = visibility[val?.employee_name];
                        return (
                          <>
                            {!isVisible && (
                              <tr key={`row-${ind}`}>
                                <td className="text-start">
                                  <div
                                    className="icon-t"
                                    onClick={() =>
                                      toggleDiv(val?.employee_name)
                                    }
                                  >
                                    <span className="ic">
                                      {isVisible ? "Hide" : <CgAdd />}{" "}
                                    </span>
                                    {val?.employee_name}
                                  </div>
                                </td>
                                <td className="text-center"></td>
                                <td className="text-center">
                                  {formatNumberWithFraction(
                                    val?.total_actual_worked_ftes,
                                    1
                                  )}
                                </td>
                                <td className=" text-center">
                                  {formatNumberWithFraction(
                                    val?.total_target_worked_ftes,
                                    1
                                  )}
                                </td>
                                <td className=" text-center">
                                  {formatNumberWithFraction(
                                    val?.total_worked_fte_variance,
                                    1
                                  )}
                                </td>
                                <td className="text-center red-box">
                                  {formatNumberWithFraction(
                                    val?.total_worked_fte_variance_percentage,
                                    1
                                  )}
                                </td>
                                <td className="text-center">
                                  {formatNumberWithFraction(
                                    val?.total_actual_paid_ftes,
                                    1
                                  )}
                                </td>
                                <td className=" text-center">
                                  {formatNumberWithFraction(
                                    val?.total_target_paid_ftes,
                                    1
                                  )}
                                </td>
                                <td className=" text-center">
                                  {formatNumberWithFraction(
                                    val?.total_paid_fte_variance,
                                    1
                                  )}
                                </td>
                                <td className="text-center  red-box">
                                  {formatNumberWithFraction(
                                    val?.total_paid_fte_variance_percentage,
                                    1
                                  )}
                                </td>
                                <td className=" text-center">
                                  {formatNumberWithFraction(
                                    val?.actual_pto_factor,
                                    1
                                  )}
                                </td>
                                <td className="text-center">
                                  {formatNumberWithFraction(
                                    val?.target_pto_factor,
                                    1
                                  )}
                                </td>
                              </tr>
                            )}
                            {isVisible && (
                              <tr key={`child-row-${ind}`}>
                                <td colSpan={12} className="child-table">
                                  <div className="table-container">
                                    <table className="">
                                      {val?.cost_centers?.map(
                                        (costCenter, ind) => (
                                          <>
                                            <tr key={ind}>
                                              <>
                                                {ind === 0 ? (
                                                  <td
                                                    className="text-start"
                                                    rowSpan={8}
                                                  >
                                                    <div
                                                      className="icon-t"
                                                      onClick={() =>
                                                        toggleDiv(
                                                          val?.employee_name
                                                        )
                                                      }
                                                    >
                                                      <span className="ic">
                                                        <CgRemove />
                                                      </span>
                                                      {val?.employee_name}
                                                    </div>
                                                  </td>
                                                ) : (
                                                  <td></td>
                                                )}
                                                <td className="text-center">
                                                  {
                                                    costCenter?.cost_center_number
                                                  }{" "}
                                                  -{" "}
                                                  {costCenter?.cost_center_name}
                                                </td>
                                                <td className="text-center">
                                                  {formatNumberWithFraction(
                                                    costCenter?.actual_worked_ftes,
                                                    1
                                                  )}
                                                </td>
                                                <td className=" text-center">
                                                  {formatNumberWithFraction(
                                                    costCenter?.target_worked_ftes,
                                                    1
                                                  )}
                                                </td>
                                                <td className=" text-center">
                                                  {formatNumberWithFraction(
                                                    costCenter?.worked_fte_variance,
                                                    1
                                                  )}
                                                </td>
                                                <td className="text-center red-box">
                                                  {formatNumberWithFraction(
                                                    costCenter?.worked_fte_variance_percentage,
                                                    1
                                                  )}
                                                </td>
                                                <td className="text-center">
                                                  {formatNumberWithFraction(
                                                    costCenter?.actual_paid_ftes,
                                                    1
                                                  )}
                                                </td>
                                                <td className=" text-center">
                                                  {formatNumberWithFraction(
                                                    costCenter?.target_paid_ftes,
                                                    1
                                                  )}
                                                </td>
                                                <td className=" text-center">
                                                  {formatNumberWithFraction(
                                                    costCenter?.paid_fte_variance,
                                                    1
                                                  )}
                                                </td>
                                                <td className="text-center  red-box">
                                                  {formatNumberWithFraction(
                                                    costCenter?.paid_fte_variance_percentage,
                                                    1
                                                  )}
                                                </td>
                                                <td className=" text-center">
                                                  {formatNumberWithFraction(
                                                    costCenter?.actual_pto_factor,
                                                    1
                                                  )}
                                                </td>
                                                <td className="text-center">
                                                  {formatNumberWithFraction(
                                                    costCenter?.target_pto_factor,
                                                    1
                                                  )}
                                                </td>
                                              </>
                                            </tr>

                                            {ind ===
                                              val?.cost_centers.length - 1 && (
                                              <tr>
                                                <>
                                                  <td></td>
                                                  <td className="text-center">
                                                    <strong>Total</strong>
                                                  </td>
                                                  <td className="text-center">
                                                    <strong>
                                                      {formatNumberWithFraction(
                                                        val?.total_actual_worked_ftes,
                                                        1
                                                      )}
                                                    </strong>
                                                  </td>
                                                  <td className=" text-center">
                                                    <strong>
                                                      {" "}
                                                      {formatNumberWithFraction(
                                                        val?.total_target_worked_ftes,
                                                        1
                                                      )}
                                                    </strong>
                                                  </td>
                                                  <td className=" text-center">
                                                    <strong>
                                                      {formatNumberWithFraction(
                                                        val?.total_worked_fte_variance,
                                                        1
                                                      )}
                                                    </strong>
                                                  </td>
                                                  <td className="text-center red-box">
                                                    <strong>
                                                      {formatNumberWithFraction(
                                                        val?.total_worked_fte_variance_percentage,
                                                        1
                                                      )}
                                                    </strong>
                                                  </td>
                                                  <td className="text-center">
                                                    <strong>
                                                      {formatNumberWithFraction(
                                                        val?.total_actual_paid_ftes,
                                                        1
                                                      )}
                                                    </strong>
                                                  </td>
                                                  <td className=" text-center">
                                                    <strong>
                                                      {formatNumberWithFraction(
                                                        val?.total_target_paid_ftes,
                                                        1
                                                      )}
                                                    </strong>
                                                  </td>
                                                  <td className=" text-center">
                                                    <strong>
                                                      {formatNumberWithFraction(
                                                        val?.total_paid_fte_variance,
                                                        1
                                                      )}
                                                    </strong>
                                                  </td>
                                                  <td className="text-center  red-box">
                                                    <strong>
                                                      {formatNumberWithFraction(
                                                        val?.total_paid_fte_variance_percentage,
                                                        1
                                                      )}
                                                    </strong>
                                                  </td>
                                                  <td className=" text-center">
                                                    <strong>
                                                      {formatNumberWithFraction(
                                                        val?.actual_pto_factor,
                                                        1
                                                      )}
                                                    </strong>
                                                  </td>
                                                  <td className="text-center">
                                                    <strong>
                                                      {formatNumberWithFraction(
                                                        val?.target_pto_factor,
                                                        1
                                                      )}
                                                    </strong>
                                                  </td>
                                                </>
                                              </tr>
                                            )}
                                          </>
                                        )
                                      )}
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "200px" }}
                      >
                        <p className="text-secondary display-6">
                          No Data Found
                        </p>
                      </div>
                    )
                  ) : (
                    <div>
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                      <TableLoader themeMode={themeMode} />
                    </div>
                  )}
                </tbody>
                <tbody className="ftr first-col-w">
                  {isTableLoading ? (
                    <div>
                      <TableLoader themeMode={themeMode} />
                    </div>
                  ) : !isEmpty(executiveFteResult) ? (
                    <tr className="bt">
                      <td className="text-start">
                        <strong>Total</strong>
                      </td>
                      <td className="text-center">
                        <strong></strong>
                      </td>
                      <td className="text-center">
                        <strong>
                          {formatNumberWithFraction(
                            executiveFteResult?.total_actual_worked_ftes_sum,
                            1
                          )}
                        </strong>
                      </td>
                      <td className="text-center">
                        <strong>
                          {" "}
                          {formatNumberWithFraction(
                            executiveFteResult?.total_target_worked_ftes_sum,
                            1
                          )}
                        </strong>
                      </td>
                      <td className="text-center">
                        <strong>
                          {" "}
                          {formatNumberWithFraction(
                            executiveFteResult?.total_worked_fte_variance_sum,
                            1
                          )}
                        </strong>
                      </td>
                      <td className="text-center  red-box">
                        <strong>
                          {" "}
                          {formatNumberWithFraction(
                            executiveFteResult?.total_worked_fte_variance_percentage_sum,
                            1
                          )}
                        </strong>
                      </td>
                      <td className="text-center">
                        <strong>
                          {" "}
                          {formatNumberWithFraction(
                            executiveFteResult?.total_actual_paid_ftes_sum,
                            1
                          )}
                        </strong>
                      </td>
                      <td className="text-center">
                        <strong>
                          {" "}
                          {formatNumberWithFraction(
                            executiveFteResult?.total_target_paid_ftes_sum,
                            1
                          )}
                        </strong>
                      </td>
                      <td className="text-center">
                        <strong>
                          {" "}
                          {formatNumberWithFraction(
                            executiveFteResult?.total_paid_fte_variance_sum,
                            1
                          )}
                        </strong>
                      </td>
                      <td className="text-center  red-box">
                        <strong>
                          {" "}
                          {formatNumberWithFraction(
                            executiveFteResult?.total_paid_fte_variance_percentage,
                            1
                          )}
                        </strong>
                      </td>
                      <td className="text-center">
                        <strong>
                          {" "}
                          {formatNumberWithFraction(
                            executiveFteResult?.actual_pto_factor_sum,
                            1
                          )}
                        </strong>
                      </td>
                      <td className="text-center">
                        <strong>
                          {" "}
                          {formatNumberWithFraction(
                            executiveFteResult?.target_pto_factor_sum,
                            1
                          )}
                        </strong>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
}

export default ExcutiveFTEReport;
