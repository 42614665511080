
import React from 'react'
import {useContext} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './index.css';
import Ico2 from "../../../../assets/img/info.png";
import Ico3 from "../../../../assets/img/warning.png";
import Ico4 from "../../../../assets/img/critical.png";
import { ThemeContext } from "../../../../providers/ThemeProvider";

function PageUseModal(props) {
  const [themeMode, setTheme] = useContext(ThemeContext);
  return (
    <Modal
    {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className='max-w-720 '
  >
    <Modal.Header closeButton className='custom-font-size custom-position'>
        <Modal.Title id="contained-modal-title-vcenter" className='text-center w-100 ft-title color-black'>
       Filter Option Guide
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className='custom-font-size text-indent-15'>
        <h4 className={`fw-normal color-black f-18 f-16 mb-0 
         ${themeMode.theme === 'DARK' ? "redt" : "redLight"}
       `}><span className="circle"></span> Variance</h4>
      <p>- Shifts where worked hours exceed scheduled hours by more than .1 hours (6 minutes)</p>
        <h4 className=' fw-normal color-black f-18 f-16 mb-0 yellowt'><span className="circle"></span> Incidental</h4>
        <p>- Shifts where worked hours exceed scheduled hours by, at most, .1 hours (6 minutes)</p>
           <h4 className=' fw-normal color-black f-18 f-16 mb-0 whitet'><span className="circle"></span> Schedule</h4>
        <p>- Displays all scheduled hours</p>
        <h4 className={`fw-normal color-black f-18 f-16 mb-0 
         ${themeMode.theme === 'DARK' ? "purplet" : "purpleLight"}
       `}><span className="circle"></span> Below Schedule</h4>
        <p>- Shifts where worked hours are less then scheduled hours</p>
        <h4 className=' fw-normal color-black f-18 f-16 mb-0 orangeyt'><span className="circle"></span> Missing Schedule</h4>
        <p>- Shifts where worked hours occurred without a schedule</p>
        <h4 className={`fw-normal color-black f-18 f-16 mb-0 
         ${themeMode.theme === 'DARK' ? "greent" : "greenLight"}
       `}><span className="circle"></span> No Variance</h4>
        <p>- Shifts where worked hours match scheduled hours</p>
    </Modal.Body>
    
  </Modal>
  )
}

export default PageUseModal

