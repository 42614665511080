import "./index.css";
import React from "react";
import { useEffect, useContext, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import InfoModal from "../../components/ActiveDailyManagementComponents/shared/InfoModal";
import FooterModal from "../../components/ActiveDailyManagementComponents/shared/footerModal";
import ContentLoader from "react-content-loader";
import PageLayout from "../../layouts/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get, isEmpty } from "lodash";
import { API_CALL_URL } from "../../constants";
import { API_CALL_URL_PRIVATE } from "../../constants";
import axios from "../../axios";
import Axios from "axios";
import { getTableRecordWithSorting } from "../../services";
import {
  capitalizeAllLetters,
  formatNumberWithFraction,
} from "../../utils/helper";
import {
  setUpdateDate,
  setUpdateIndex,
  setUpdateStack,
  setExceptionIndex,
} from "../../store/actions/Compensate";
import DescIcon from "../../assets/img/desc.png";
import AscIcon from "../../assets/img/asc.png";
import { ThemeContext } from "../../providers/ThemeProvider";
import PsLogo from "../../assets/img/pslogo.png";
import Leaders from "../../assets/img/leader.svg";
import SHIFT_FORM from "../../constants/shiftForm";
import {
  CustomDropdown,
  DropdownIndicator,
  ChevronDown,
} from "../../Styled/DropdownStyle";
import Button from "@atlaskit/button";
import Select from "react-select";
import { decryptedFunction, dataEnCrypt } from "../../utils/helper";

const HeaderLoader = () => (
  <ContentLoader viewBox="0 0 280 17">
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="17" />
  </ContentLoader>
);

const HeaderLoaderText = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 10"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "90%" }} height="10" />
  </ContentLoader>
);

const TableLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 8"
    style={{ padding: "0" }}
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="8" />
  </ContentLoader>
);

function ActiveManagement({ history }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const buisnessHeader = useSelector(
    (state) => state.compensate.buisnessHeader
  );
  const isBuisnessUnitSet = localStorage.getItem("buisnessUnit");
  const IsShowAdmTable = JSON.parse(localStorage.getItem("psdata"))?.data
    ?.show_adm_landing_table;
  const location = useLocation();
  const { state } = location;
  const [themeMode, setTheme] = useContext(ThemeContext);
  const [modalShow, setModalShow] = React.useState(false);
  const [footerShow, setFooterShow] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableApiData, setTableApiData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [totalvalue, setTotalvalue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [sortType, setSortType] = useState("asc");
  const [sortName, setsortName] = useState("");
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [metaData, setMetaData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [costCenterList, setCostCenterList] = useState([]);
  const [page, setPage] = useState(1);
  const [cancelToken, setCancelToken] = useState(null);
  const [isDropdownOpenThree, setIsDropdownOpenThree] = useState(false);
  const [dropdownValueThree, setDropdownValueThree] = useState("");
  const [buisnessData, setBuisnessData] = useState({});
  const [mounted, setMounted] = useState(false);
  const [filterDateList, setFilterDateList] = useState([]);
  const [dropdownValueTwo, setDropdownValueTwo] = useState(null);
  const [isDropdownOpentwo, setIsDropdownOpentwo] = useState(false);

  const fetchDataRecursive = async (
    pyDate,
    initialCall = true,
    searchKey,
    limit,
    cancelToken,
    buisnessCategory
  ) => {
    try {
      if (initialCall) {
        setTimeout(() => setIsTableLoading(true), 0);
      }

      const parData = {
        vp_id: +id,
        payperiod_end_date: pyDate ? pyDate : dropdownValueTwo?.value,
      };

      if (searchKey) {
        parData.searchKeyword = searchKey;
      }

      if (limit) {
        parData.limit = limit;
      }

      if (buisnessCategory) {
        parData.businessUnit = buisnessCategory;
      }

      const encryptData = dataEnCrypt(parData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/leaderMetrics/getDirectStaffData`,
        { payloadData: encryptData },
        { cancelToken: cancelToken }
      );
      const responseData = decryptedFunction(response?.data?.responseData);
      setTableApiData(responseData?.data);
      setTableData(responseData?.data?.tableData);

      if (initialCall) {
        setIsTableLoading(false);
      }
    } catch (error) {
      setIsTableLoading(false);
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error fetching data:", error);
      }
    }
  };

  const getHeadData = async (pyDate) => {
    setIsLoading(true);

     const parData = {
          payperiod_end_date: pyDate ? pyDate : dropdownValueTwo?.value,
        };
     const encryptData = dataEnCrypt(parData);

    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/leaderMetrics/getheaderData`,
        {
          payloadData: encryptData,
        }
      );
      const data = decryptedFunction(resp?.data?.responseData);
      setIsLoading(false);
      setHeadData(data.data);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (didMountRef.current) {
      return;
    }
    getMetaData();
    getRefreshSchedule();
    getBuisnesUnit();
    getFilterDate();
    didMountRef.current = true;
  }, []);

  const sortingHandle = (key, bindname = null) => {
    const dataToSort = bindname ? get(tableData, bindname, []) : tableData;
    const sortdata = getTableRecordWithSorting(dataToSort, key, sortType);
    setSortType(sortType === "asc" ? "desc" : "asc");
    activityLog(SHIFT_FORM.TABLE_NAMES[key] ?? "", sortType);

    if (bindname) {
      setTableData({ ...tableData, [bindname]: sortdata });
    } else {
      setTableData(sortdata);
    }

    setsortName(key);
  };

  const emptyActiveData = () => {
    getHeadData();
    setSelectedRow(null);
  };

  const handleDoubleExceptional = (val) => {
    navigate(`/activedailymanagement/adm-summary/${val?.cost_center_slug}`);
  };

  const getMetaData = async () => {
    try {
      const param = {
        application_id: 105,
      };
      const encryptData = dataEnCrypt(param);
      const response = await axios.post(`${API_CALL_URL_PRIVATE}/metaData`, {
        payloadData: encryptData,
      });
      const data = decryptedFunction(response?.data?.responseData);
      setMetaData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // document.title = metaData?.meta_title || "Active Daily Management";
    document.title = "Leader Dashboard";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${metaData?.favicon}` || PsLogo;
  }, [metaData]);

  const getRefreshSchedule = async (refreshType) => {
    try {
      const parData = {};
      if (refreshType) {
        parData.clicked = 1;
      }

      const encryptData = dataEnCrypt(parData);

      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/fileRefreshSchedule`,
        { payloadData: encryptData }
      );
      const data = decryptedFunction(response?.data?.responseData);
      setScheduleData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSearchInput = async (key) => {
    setIsTableLoading(true);
    try {
      if (cancelToken) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      const newCancelToken = Axios.CancelToken.source();
      setCancelToken(newCancelToken);

      if (key !== "") {
        await fetchDataRecursive(dropdownValueTwo?.value, true, key, 1000, newCancelToken.token);
      } else {
        await fetchDataRecursive(dropdownValueTwo?.value, true, key, null, newCancelToken.token);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBuisnesUnit = async (ccn) => {
    try {
      const resp = await axios.post(
        `${API_CALL_URL_PRIVATE}/admGeneral/BusinessUnitDescriptionList`
      );
      const data = decryptedFunction(resp?.data?.responseData);

      setBuisnessData(
        data?.data?.data?.map((val) => {
          return {
            label: val,
            value: val,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isBuisnessUnitSet !== null) {
      setDropdownValueThree({
        label: isBuisnessUnitSet,
        value: isBuisnessUnitSet,
      });
    }
  }, [isBuisnessUnitSet]);

  // useEffect(() => {
  //   if (mounted) {
  //     fetchDataRecursive();
  //   } else {
  //     setMounted(true);
  //   }
  // }, [buisnessHeader]);

  const activityLog = async (table_column, sortType) => {
    try {
      const paramData = {
        activity_id: 47,
        page_type: "Active Daily Management",
        application_id: 105,
        table_name: "Active Daily Management",
        table_column: table_column,
        sort_order: sortType === "asc" ? "Ascending" : "Descending",
        searchKeyword: searchBoxValue,
        businessUnit: isBuisnessUnitSet,
      };
      const encryptData = dataEnCrypt(paramData);
      const response = await axios.post(
        `${API_CALL_URL_PRIVATE}/activity-log/create`,
        { payloadData: encryptData }
      );
    } catch (err) {
      console.log(err);
    }
  };

    const getFilterDate = async () => {
      setIsTableLoading(true);
      try {
        const response = await axios.post(
          `${API_CALL_URL_PRIVATE}/genral/payPeriodDates`
        );
        const data = decryptedFunction(response?.data?.responseData);
        setFilterDateList(
          data?.data?.payPeriodEndDates.map((item) => {
            return {
              label: item.display_date,
              value: item.payload_date,
            };
          })
        );

          if (state?.urldate) {
             fetchDataRecursive(state?.urldate?.value);
             getHeadData(state?.urldate?.value);
              const matchingCostCenter = state?.urldate?.value;
              setDropdownValueTwo({
                label: state?.urldate?.label,
                value: state?.urldate?.value,
              });
              return matchingCostCenter;
          } else {
            fetchDataRecursive();
            getHeadData();
              const matchingCostCenter = data?.data?.payPeriodEndDates[0];
              setDropdownValueTwo({
                label: matchingCostCenter?.display_date,
                value: matchingCostCenter?.payload_date,
              });
              return matchingCostCenter;
          }
  
      } catch (err) {
        console.log(err);
      }
    };

  const toggleOpenTwo = () => {
    setIsDropdownOpentwo((prevIsOpen) => !prevIsOpen);
  };

  const selectStyles = {
    control: (provided) => ({ ...provided, minWidth: 150, margin: 8 }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  };

  return (
    <PageLayout>
      <div className="activedailymanagement-s">
        <Row className="gx-2 mt-0 align-items-center">
          <Col className="d-flex " md={{ span: 2 }}>
            <Link
              className="f-14 white-text-dark-mode"
              to={`/leadermetrics`}
              state={{
                urldate: {
                  label: dropdownValueTwo?.label,
                  value: dropdownValueTwo?.value,
                },
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-arrow-left " /> Back
            </Link>
          </Col>
          <Col md={{ span: 8 }}>
            <div className="text-center">
              {isTableLoading ? (
                <HeaderLoaderText themeMode={themeMode} />
              ) : (
                <>
                  <div className="d-flex mobile-flex-wrap justify-content-center align-items-center Bi_Su">
                    <h3 className="text-center color-black m-0 d-flex align-items-center justify-content-center">
                      <img src={Leaders} alt="" height={38} /> &nbsp; Direct
                      Staff Under {tableApiData?.vp_name} -{" "}
                    </h3>
                    <div className="custom-select-v">
                      <CustomDropdown
                        isDropdownOpen={isDropdownOpentwo}
                        onClose={toggleOpenTwo}
                        target={
                          <Button
                            iconAfter={<ChevronDown />}
                            onClick={toggleOpenTwo}
                            isSelected={isDropdownOpentwo}
                          >
                            {dropdownValueTwo ? dropdownValueTwo.label : ""}
                          </Button>
                        }
                      >
                        <Select
                          autoFocus
                          backspaceRemovesValue={false}
                          components={{
                            DropdownIndicator,
                            IndicatorSeparator: null,
                          }}
                          controlShouldRenderValue={false}
                          hideSelectedOptions={false}
                          isClearable={false}
                          menuIsOpen={isDropdownOpentwo}
                          options={filterDateList}
                          placeholder="Search..."
                          styles={selectStyles}
                          tabSelectsValue={false}
                          onChange={(e) => {
                            toggleOpenTwo();
                            setDropdownValueTwo(e);
                            fetchDataRecursive(e?.value);
                            getHeadData(e?.value);
                            setSearchBoxValue("");
                          }}
                          value={dropdownValueTwo}
                        />
                      </CustomDropdown>
                    </div>
                  </div>
                </>
              )}

              <div className="d-flex justify-content-center align-items-center">
                <p className="font-15 mb-0 mt-1">
                  {isLoading ? (
                    <HeaderLoaderText themeMode={themeMode} />
                  ) : (
                    get(headData, "filter_text", "")
                  )}
                </p>

                {isLoading ? (
                  <HeaderLoaderText themeMode={themeMode} />
                ) : headData.show_cross_button ? (
                  <button
                    type="button"
                    class="custom-close-button"
                    onClick={() => {
                      emptyActiveData();
                    }}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center pay-period color-red">
              {get(headData, "imp_notes", "")}
            </div>
          </Col>
          <Col md={2} className="header-bar h-45p">
            {/* <div className="col ">
              <Select
                inputValue={searchBoxValue}
                onChange={(e) => {
                  navigate(
                    `/activedailymanagement/adm-summary/${e.value}`
                  );
                }}
                onInputChange={(inputValue, { action }) => {
                  if (action === "input-change") {
                    setSearchBoxValue(inputValue);
                    getSearchInput(inputValue);
                  }
                }}
                options={costCenterList}
                placeholder={"Search..."}
                isSearchable
                onBlurResetsInput={false}
              />
            </div> */}
            {IsShowAdmTable ? (
              <div className="input-group mb-3">
                <input
                  type="text"
                  value={searchBoxValue}
                  className="form-control py-2 bg-transparent border-light"
                  placeholder="Search..."
                  onChange={(e) => {
                    getSearchInput(e.target.value);
                    setSearchBoxValue(e.target.value);
                  }}
                />
              </div>
            ) : (
              <div></div>
            )}
          </Col>

          <InfoModal show={modalShow} onHide={() => setModalShow(false)} />
          <FooterModal
            show={footerShow}
            dataFooter={scheduleData}
            onHide={() => setFooterShow(false)}
          />
        </Row>

        <Row className="gx-2">
          <Col md={12} className="mt-2 h-240-leader">
            <div className="bg-white p-3 h-100">
              {IsShowAdmTable ? (
                <Table
                  responsive
                  className="table activity-tabel mb-0 ind-table tb2  table-hover exceptions-table scroll-table leaderdash-table"
                >
                  <thead className="all-side-border">
                    <tr className="vertical-align-middle">
                      <th
                        rowSpan={2}
                        className="text-center cursor-pointer"
                        onClick={() => sortingHandle("employee_name")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Direct Staff</div>
                          <div className="ms-2 ms-5">
                            {sortType === "asc" &&
                            sortName === "employee_name" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      <th
                        colSpan={2}
                        className="text-center cursor-pointer"
                        onClick={() => sortingHandle("overtime")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div> Critcal Exceptions</div>
                        </div>
                      </th>
                      <th
                        colSpan={3}
                        className="text-center cursor-pointer leader-divide"
                        onClick={() =>
                          sortingHandle("critical_exception_count")
                        }
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Missed Meal and Break Hours</div>
                        </div>
                      </th>
                      <th
                        colSpan={3}
                        className="text-center cursor-pointer leader-divide"
                        onClick={() => sortingHandle("schedule_variance")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Overtime Hours</div>
                        </div>
                      </th>
                      <th
                        colSpan={4}
                        className="text-center cursor-pointer leader-divide"
                        onClick={() => sortingHandle("missed_meal_break")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Unscheduled Hours</div>
                        </div>
                      </th>
                    </tr>
                    <tr className="vertical-align-middle">
                      <th
                        className="text-center cursor-pointer"
                        onClick={() =>
                          sortingHandle("critical_exception_count")
                        }
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Critcal Exception Count</div>
                          <div className="ms-2">
                            {sortType === "asc" &&
                            sortName === "critical_exception_count" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      <th
                        className="text-center cursor-pointer"
                        onClick={() =>
                          sortingHandle("when_posted_days_interval_avg")
                        }
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Average Exception Age</div>
                          <div className="ms-2">
                            {sortType === "asc" &&
                            sortName === "when_posted_days_interval_avg" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      <th
                        className="text-center cursor-pointer leader-divide"
                        onClick={() => sortingHandle("missedMealsHourSum")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Missed Meal Hours </div>
                          <div className="ms-2">
                            {sortType === "asc" &&
                            sortName === "missedMealsHourSum" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      <th
                        className="text-center cursor-pointer "
                        onClick={() => sortingHandle("missedBreaksHourSum")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Missed Break Hours </div>
                          <div className="ms-2">
                            {sortType === "asc" &&
                            sortName === "missedBreaksHourSum" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      <th
                        className="text-center cursor-pointer"
                        onClick={() => sortingHandle("staff")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Staff</div>
                          <div className="ms-2">
                            {sortType === "asc" && sortName === "staff" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      <th
                        className="text-center cursor-pointer leader-divide"
                        onClick={() => sortingHandle("overtime_worked_hours")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Overtime % of Worked Hours </div>
                          <div className="ms-2">
                            {sortType === "asc" &&
                            sortName === "overtime_worked_hours" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      <th
                        className="text-center cursor-pointer"
                        onClick={() => sortingHandle("incidental")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Incidental % </div>
                          <div className="ms-2">
                            {sortType === "asc" && sortName === "incidental" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      <th
                        className="text-center cursor-pointer"
                        onClick={() => sortingHandle("total_overtime_hours")}
                      >
                        <div className="d-flex justify-content-cente3r align-items-center">
                          <div>Total Overtime Hours</div>
                          <div className="ms-2">
                            {sortType === "asc" &&
                            sortName === "total_overtime_hours" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      <th
                        className="text-center cursor-pointer leader-divide"
                        onClick={() => sortingHandle("worked_hours")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Worked Hours</div>
                          <div className="ms-2">
                            {sortType === "asc" &&
                            sortName === "worked_hours" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      <th
                        className="text-center cursor-pointer"
                        onClick={() => sortingHandle("scheduled_hours")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Scheduled Hours </div>
                          <div className="ms-2">
                            {sortType === "asc" &&
                            sortName === "scheduled_hours" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      <th
                        className="text-center cursor-pointer"
                        onClick={() => sortingHandle("scheduled_variance")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Scheduled Variance </div>
                          <div className="ms-2">
                            {sortType === "asc" &&
                            sortName === "scheduled_variance" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                      <th
                        className="text-center cursor-pointer"
                        onClick={() => sortingHandle("cost_center_number")}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div>Staff W/O Schedule</div>
                          <div className="ms-2">
                            {sortType === "asc" &&
                            sortName === "cost_center_number" ? (
                              <img src={AscIcon} alt="" />
                            ) : (
                              <img src={DescIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="crossi h-520 all-new-border">
                    {!isTableLoading ? (
                      tableData.length > 0 ? (
                        tableData?.map((val, ind) => {
                          return (
                            <tr
                              key={ind}
                              style={{ cursor: "pointer" }}
                              className={
                                selectedRow === val?._id
                                  ? "selected-active-row"
                                  : ""
                              }
                            >
                              <td>
                                <div
                                // to={`/activedailymanagement/exceptionsummary/${val.cost_center_slug}`}
                                >
                                  {val?.employee_name}
                                </div>
                              </td>
                              <td>
                                {formatNumberWithFraction(
                                  val?.critical_exception_count,
                                  0
                                )}
                              </td>
                              <td>
                                {formatNumberWithFraction(
                                  val?.when_posted_days_interval_avg,
                                  0
                                )}
                              </td>
                              <td className="leader-divide">
                                {formatNumberWithFraction(
                                  val?.missedMealsHourSum,
                                  0
                                )}
                              </td>
                              <td>
                                {formatNumberWithFraction(
                                  val?.missedBreaksHourSum,
                                  0
                                )}
                              </td>
                              <td>
                                {formatNumberWithFraction(val?.employes, 0)}
                              </td>
                              <td className="text-center leader-divide">
                                <div className="d-flex justify-content-center align-items-center">
                                  <span
                                    className={
                                      val?.overtime_worked_hours <= 4
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                  >
                                    {formatNumberWithFraction(
                                      val?.overtime_worked_hours,
                                      1
                                    )}
                                    %
                                  </span>
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="d-flex justify-content-center align-items-center">
                                  <span
                                    className={
                                      val?.incidental <= 1
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                  >
                                    {formatNumberWithFraction(
                                      val?.incidental,
                                      1
                                    )}
                                    %
                                  </span>
                                </div>
                              </td>
                              <td className="text-center">
                                <span>
                                  {formatNumberWithFraction(
                                    val?.total_overtime_hours,
                                    1
                                  )}
                                </span>
                              </td>
                              <td className="text-center leader-divide">
                                <div>
                                  {" "}
                                  {formatNumberWithFraction(
                                    val?.worked_hours,
                                    1
                                  )}
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="d-flex justify-content-center align-items-center">
                                  {formatNumberWithFraction(
                                    val?.scheduled_hours,
                                    1
                                  )}
                                </div>
                              </td>
                              <td className="text-center">
                                <span>
                                  {formatNumberWithFraction(
                                    val?.scheduled_variance,
                                    1
                                  )}
                                </span>
                              </td>
                              <td className="text-center">
                                <div className="d-flex justify-content-center align-items-center">
                                  {formatNumberWithFraction(val?.staff, 0)}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "200px" }}
                        >
                          <p className="text-secondary display-6">
                            No Data Found
                          </p>
                        </div>
                      )
                    ) : (
                      <div>
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                        <TableLoader themeMode={themeMode} />
                      </div>
                    )}
                  </tbody>
                  <tbody className="ftr ">
                    {isTableLoading ? (
                      <div>
                        <TableLoader themeMode={themeMode} />
                      </div>
                    ) : !isEmpty(totalvalue) ? (
                      <tr className="bt">
                        <td>
                          <strong>Total</strong>
                        </td>
                        <td className="text-center">
                          <strong>
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                            }).format(get(totalvalue, "schedule_variance", 0))}
                          </strong>
                        </td>
                        <td className="text-center">
                          <strong>
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                            }).format(get(totalvalue, "overtime", 0))}
                          </strong>
                        </td>
                        <td className="text-center">
                          <strong>
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                            }).format(get(totalvalue, "agency", 0))}
                          </strong>
                        </td>
                        <td className="text-center">
                          <strong>
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                            }).format(get(totalvalue, "missed_meal_break", 0))}
                          </strong>
                        </td>
                        <td className="text-center">
                          <strong>
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              // minimumFractionDigits: 1,
                              // maximumFractionDigits: 1,
                            }).format(
                              get(totalvalue, "critical_exception_count", 0)
                            )}
                          </strong>
                        </td>
                        <td className="text-center">
                          <strong>
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            }).format(get(totalvalue, "hours_variance", 0))}
                          </strong>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </Table>
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col md={12} className="ft-text mt-2 mb-2 px-1 ft-text">
            <div className="row  f-14 d-flex justify-content-between gx-2 m-0">
              <div className="col-12 text-end text-mobile-center bg-white">
                <span
                  className="d-inline-block p-2  cursor-pointer"
                  onClick={() => {
                    getRefreshSchedule(true);
                    setFooterShow(true);
                  }}
                >
                  {scheduleData?.exception?.currentFileMsg}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
}

export default ActiveManagement;
