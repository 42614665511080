import { useContext } from "react";
import Chart from "react-apexcharts";
import ContentLoader from "react-content-loader";
import { ThemeContext } from "../../../providers/ThemeProvider";
import { formatNumberWithFraction } from "../../../utils/helper";


import moment from "moment";
import { FONT_WEIGHT } from "ag-charts-community/dist/esm/es6/module-support";

const MyLoader = ({ themeMode }) => (
  <ContentLoader viewBox="0 0 100 25"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="1" rx="2" ry="2" width="100" height="24" />
  </ContentLoader>
);

function BiweeklyLineChart({
  admitlinechartat,
  admitlinechartseries,
  isLoader,
  color,
  title,
  data,
  minData,
  maxMaxData,
  varianceTrendType,
}) {
  const [themeMode, setTheme] = useContext(ThemeContext);

  const formattedData = data?.graphData
    ? data.graphData
        .filter(
          (item) =>
            !(
              item?.actual_worked_ftes === 0 &&
              item?.target_worked_ftes === 0 &&
              item?.dollar_variance === 0
            )
        )
        .map((item) => ({
          actual_worked_ftes: item?.actual_worked_ftes ?? 0,
          target_worked_ftes: item?.target_worked_ftes ?? 0,
          dollar_variance: item?.dollar_variance ?? 0,
          payPeriodEndDate: item?.payPeriodEndDate ?? "",
        }))
    : [];

  const formatLargeNumbers = (value) => {
    if (value >= 1_000_000_000) {
      return (value / 1_000_000_000).toFixed(0) + "B"; // Billion
    } else if (value >= 1_000_000) {
      return (value / 1_000_000).toFixed(0) + "M"; // Million
    } else if (value >= 1_000) {
      return (value / 1_000).toFixed(0) + "K"; // Thousand
    }
    return value; // Smaller values are returned as-is
  };

  var options = {
    chart: {
      //  height: 350,
      //  type: "line",
      stacked: false,

      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Disable zooming behavior
      },
    },

    dataLabels: {
      enabled: true,
      hideOverflowingLabels: true,
      formatter: function (val, { seriesIndex, w }) {

        let offsetYValue = -8; // Default offsetY

        // Different settings for each series
        if (seriesIndex === 0) {
          offsetYValue = -8; // Adjust offsetY for Dollar Variance
        } else if (seriesIndex === 1) {
          offsetYValue = 14; // Adjust offsetY for Target Worked FTEs
        } else if (seriesIndex === 2) {
          offsetYValue = -16; // Default for Actual Worked FTEs
        }

        // Set dynamically via w.globals
        w.config.dataLabels.offsetY = offsetYValue;

        if (seriesIndex === 0) {
          return `$${formatNumberWithFraction(val, 0)}`;
        } else if (seriesIndex === 1 || seriesIndex === 2) {
          return formatNumberWithFraction(val, 1);
        }
        return val;
      },

      offsetY: -12,
      style: {

        fontSize: "11px",
        fontWeight: "normal",

        textShadow: "2px 2px 4px rgba(0,0,0,0.7)",
        colors: themeMode.theme === "DARK" ? ["#F5F5F5"] : ["black"],
        padding: {
          top: 30,
        },
      },
      background: {
        enabled: false,
      },
    },
    colors:
      varianceTrendType === "rolling"
        ? ["#118371", "#fcb714", "#6fb8ff"]
        : ["#9C8A5F", "#fcb714", "#6fb8ff"],

    stroke: {
      width: [2, 2],
    },
    markers: {
      size: 6,
      strokeColors: "#fff",
      hover: {
        size: 6,
        sizeOffset: 3,
      },
    },

    plotOptions: {
      bar: {
        columnWidth: "70%",
      },
    },
    xaxis: {
      categories: data?.monthResponse?.displayMonths,
      tickAmount: data?.monthResponse?.displayMonths?.length,
      type: "categories",
      title: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
          colors: themeMode.theme === "DARK" ? "white" : "black",
        },
      },
      tickPlacement: "between",
    },
    // yaxis: {
    //   show: true,
    //   labels: {
    //     formatter: function (val) {
    //       return formatNumberWithFraction(val, 0);
    //     },
    //     style: {
    //       colors: themeMode.theme === "DARK" ? "white" : "black",
    //     },
    //   },
    // },
    yaxis: [
      {
        title: {
          text: "Total Dollers",
          style: {
            color: themeMode.theme === "DARK" ? "white" : "black",
          },
        },

        labels: {
          formatter: function (val) {
            return "$" + formatLargeNumbers(val);
          },
          style: {
            colors: themeMode.theme === "DARK" ? "white" : "black",
          },
        },
      },
      {
        show: false,
        opposite: true,
        title: {
          text: "Worked FTEs",
          style: {
            color: themeMode.theme === "DARK" ? "white" : "black",
          },
        },
        labels: {
          style: {
            colors: themeMode.theme === "DARK" ? "white" : "black",
          },
        },
      },
    ],

    tooltip: {
      enabled: true,
      shared: false,
      intersect: false,
      x: {
        // Display the original payPeriodEndDate in tooltip
        formatter: (value, { dataPointIndex }) => {
          return formattedData[dataPointIndex]?.payPeriodEndDate || value;
        },
      },
      y: {
        enabled: true,
        formatter: function (val, { seriesIndex }) {
          if (seriesIndex === 0) {
            // Dollar Variance
            return `$${formatNumberWithFraction(val, 0)}`;
          } else if (seriesIndex === 1 || seriesIndex === 2) {
            // Target Worked FTEs & Actual Worked FTEs
            return formatNumberWithFraction(val, 1);
          }
          return val;
        },
      },
    },
    legend: {
      labels: {
        colors: themeMode.theme === "DARK" ? "white" : "black",
        useSeriesColors: false,
      },
      horizontalAlign: "center",
      position: "top",
      offsetY: 0,
      offsetX: 0,
    },
  };





  return (
    <div className="w-100">
      {!isLoader ? (
        <div className="bg-white pb-0 ps-2 pe-2">
          {/* <div class="f-14 text-center mb-minus-10 font-w-500" style={{ color: "themeMode.theme === 'DARK' ? 'white' : 'black'," }}>
            {title}
          </div> */}
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%" }}>
              <Chart
                options={options}
                series={[
                  {
                    name: "Dollar Variance",
                    type: "column",
                    data:
                      formattedData.length > 0
                        ? formattedData.map((item) => item.dollar_variance)
                        : [], // Send empty array if all data was 0
                  },
                  {
                    name: "Target Worked FTEs",
                    type: "line",
                    data:
                      formattedData.length > 0
                        ? formattedData.map((item) => item.target_worked_ftes)
                        : [],
                  },
                  {
                    name: "Actual Worked FTEs",
                    type: "line",
                    data:
                      formattedData.length > 0
                        ? formattedData.map((item) => item.actual_worked_ftes)
                        : [],
                  },
                ]}
                // type="line"

                height={600}
              />
            </div>
          </div>
        </div>
      ) : (
        <MyLoader themeMode={themeMode} />
      )}
    </div>
  );
}

export default BiweeklyLineChart;
