import { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./index.css";
import Table from "react-bootstrap/Table";
import Ico2 from "../../../../assets/img/info.png";
import Ico3 from "../../../../assets/img/warning.png";
import Ico4 from "../../../../assets/img/critical.png";
import axios from "../../../../axios";
import { API_CALL_URL_PRIVATE } from "../../../../constants/";

function InfoModal(props) {
  const { dataFooter } = props;
  const [apiData, setApiData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);

  //  const getPayroll = async () => {
  //   try {
  //     const { data } = await axios.post(
  //       `${API_CALL_URL_PRIVATE}/admGeneral/fileRefreshSchedule`
  //     );
  //     setScheduleData(data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //  };

  //    useEffect(() => {
  //      getPayroll();
  //    }, []);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="custom-font-size">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="fw-normal text-center w-100 color-black f-18 ft-title"
        >
          Active Daily Management Refresh Schedule
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-font-size text-indent-15 py-3">
        <Table
          responsive
          className="table  mb-0    exceptions-table ft-table-v "
        >
          <thead>
            <tr>
              <th className="f-18 text-center">File</th>
              <th className="f-18 text-center center-border">
                Showing Data as of
              </th>
              <th className="f-18 text-center">Next Scheduled Refresh</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="f-18 text-center big-font f-18">
                Exception
                <br />
                {/* <small className="f-12">Refreshed 4x Daily</small> */}
              </td>
              <td className="f-18 text-center center-border">
                {dataFooter?.exception?.currentFilDate}
              </td>
              <td className="f-18 text-center">
                {dataFooter?.exception?.upcommingFilDate}
              </td>
            </tr>
            <tr>
              <td className="f-18 text-center big-font f-18">
                Payroll
                <br />
                {/* <small className="f-12">Refreshed 4x Daily</small> */}
              </td>
              <td className="f-18 text-center center-border">
                {dataFooter?.payroll?.currentFilDate}
              </td>
              <td className="f-18 text-center">
                {dataFooter?.payroll?.upcommingFilDate}
              </td>
            </tr>
            <tr>
              <td className="f-18 text-center big-font f-18">
                UOS
                <br />
                {/* <small className="f-12">Refreshed 1x Daily</small> */}
              </td>
              <td className="f-18 text-center center-border">
                {dataFooter?.uos?.currentFilDate}
              </td>
              <td className="f-18 text-center">
                {dataFooter?.uos?.upcommingFilDate}
              </td>
            </tr>
            <tr>
              <td className="f-18 text-center big-font f-18">
                Daily Schedule
                <br />
                {/* <small className="f-12">Refreshed 4x Daily</small> */}
              </td>
              <td className="f-18 text-center center-border">
                {dataFooter?.shcemployeeschedules?.currentFilDate}
              </td>
              <td className="f-18 text-center">
                {dataFooter?.shcemployeeschedules?.upcommingFilDate}
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}

export default InfoModal;
