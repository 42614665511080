import React, { useEffect, useContext } from "react";
import ContentLoader from "react-content-loader";
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import {
  setUpdateDate,
  setUpdateIndex,
  setUpdateStack,
} from "../../store/actions/Compensate";
import { formatNumberWithFraction } from "../../utils/helper";
import { ThemeContext } from "../../providers/ThemeProvider";

const GraphLoader = ({ themeMode }) => (
  <ContentLoader
    viewBox="0 0 280 100"
    backgroundColor={themeMode.theme === "DARK" ? "#333" : "#f5f6f7"}
    foregroundColor={themeMode.theme === "DARK" ? "#555" : "#eee"}
  >
    {/* Only SVG shapes */}
    <rect x="0" y="2" rx="2" ry="2" style={{ width: "100%" }} height="100" />
  </ContentLoader>
);

const StackedBarChart = ({ data, graphClick }) => {
  const dispatch = useDispatch();
  const [themeMode, setTheme] = useContext(ThemeContext);

  const series =
    data[0]?.data?.map((product) => ({
      name: product.name,
      data: product.data,
    })) || [];

  const categories = data[0]?.graphDisplayDateWithDay || [];
  const categoriesTwo = data[0]?.dates || [];

  const handleClick = (event, chartContext, config) => {
    if (config.dataPointIndex !== undefined) {
      // Calculate which bar was clicked based on the cursor position
      if (graphClick) {
        const clickedDataPoint = categoriesTwo[config?.dataPointIndex];
        if (clickedDataPoint) {
          const clickedDate = clickedDataPoint;
          dispatch(setUpdateStack(clickedDate));
          graphClick(clickedDate);
        }
      }
    }
  };

  // // Define chart options
  const options = {
    chart: {
      // height: 400,
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: handleClick,
      },
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      formatter: (val) => {
        return formatNumberWithFraction(val, 1);
      },
    },
    // colors: ["#e80208", "#f6c140", "#6fb0e0"],
    colors: ["#bfb106", "#0673D5"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "top",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        dataLabels: {
          total: {
            enabled: true,
            formatter: function (val, opts) {
              const total = opts.w.config.series.reduce((sum, series) => {
                return Number(sum) + Number(series.data[opts.dataPointIndex]);
              }, 0);
              if (total % 1 > 0.1) {
                return formatNumberWithFraction(total);
              } else {
                return formatNumberWithFraction(total, 1);
              }
            },
            style: {
              fontSize: "14px",
              color: themeMode.theme === "DARK" ? "white" : "black",
            },
          },
        },
      },
    },

    xaxis: {
      categories: categories.map((dataPoint) => [
        dataPoint?.date,
        dataPoint?.shortDay,
      ]),
      labels: {
        formatter: function (val) {
          // Check if the value is a number before formatting
          return !isNaN(val) ? parseFloat(val).toFixed(1) : val;
        },
        style: {
          fontSize: "12px", // Reduce the font size as needed
          colors: themeMode.theme === "DARK" ? "white" : "black",
        },
        // rotate: -25,
        // rotateAlways: true,
        hideOverlappingLabels: false,
        showDuplicates: true,
        offsetY: 0,
        maxHeight: 80,
        show: true,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          // Format y-axis values to 1 decimal place
          return formatNumberWithFraction(val, 0);
        },
        style: {
          fontSize: "12px", // Reduce the font size as needed
          colors: themeMode.theme === "DARK" ? "white" : "black",
        },
      },
    },
    legend: {
      show: false,
      position: "top",
      offsetY: 0,
      fontSize: "14px",
      horizontalAlign: "right",
      textTransformation: "capitalize",
      itemMargin: {
        horizontal: 90,
        vertical: 0,
        //  with: 200
      },
      labels: {
        useSeriesColors: true, // To use the same color as the series
        style: {
          textTransformation: "capitalize", // Capitalize the first letter
          //display: "inline-block", // Display the label inline with the marker
          textAlign: "center", // Center-align the text
          fontSize: "4px",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    title: {
      show: false,
      //  text: "Exceptions By Date",
      floating: true,

      align: "center",
      margin: 55,
      offsetX: 0,
      offsetY: -12,
      style: {
        color: "#4B4D4D",
        fontSize: "16px",
        fontWeight: "500",
        fontFamily: "F37 Moon",
      },
    },
    grid: {
      show: true, // Remove both x-axis and y-axis grid lines
      borderColor: themeMode.theme === "DARK" ? "#454545" : "#d3d3d3",
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: "12px",
        color: "#000",
      },

      x: {
        show: false,
      },
      y: {
        formatter: function (val) {
          // return val + "%"
          return formatNumberWithFraction(val, 1);
        },
      },
      annotations: {
        points: "dsdsd",
      },
    },
  };
  return (
    <div id="chart" className="height-95vh">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="100%"
        // height={280}
      />
    </div>
  );
};

export default StackedBarChart;
