import React, { useState } from "react";
import "./index.css";
import { Link, NavLink } from "react-router-dom";
import { DOMAIN_URL } from "../../constants/compensate";

// --------------Dashboard Icon--------------------
import LogoThree from "../../assets/img/logo3.png";
import LogoThreeSmall from "../../assets/img/logo-s.png";
import Diconeone from "../../assets/img/home.svg";
import Diconetwo from "../../assets/img/realtime-small.png";
import Diconethree from "../../assets/img/variance-small.png";
import Diconefour from "../../assets/img/biweekly.png";
import Diconefive from "../../assets/img/target.png";
import Diconesix from "../../assets/img/post.png";
import Diconeseven from "../../assets/img/active-daily-m.png";
import Diconeeight from "../../assets/img/position-m.png";
import { get } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

const MainSideNavbar = () => {
  const userData = JSON.parse(localStorage.getItem("psdata"));
  const [userDatas, setUserDatas] = useState(get(userData, "data", []));

  const walletPath = "/home";
  const [hide, setHide] = useState(
    useLocation().pathname === walletPath ? true : false
  );
  const [isOpen, setIsOpen] = useState(true);

  const urlFunction = (e) => {
    if (e === "target") {
      return "/target";
    } else if (e === "realtime") {
      return "/realtime";
    } else if (e === "home") {
      return "/home";
    } else if (e === "activedailymanagement") {
      return "/activedailymanagement";
    } else if (e === "biweeklyreporting") {
      return "/biweeklyreporting";
    } else if (e === "variancemanagement") {
      return "/variencemanagement";
    } else if (e === "targetmanagement") {
      return "/targetmanagement";
    } else if (e === "positionmanagement") {
      return "/positionmanagement";
    } else if (e === "imaginganalytics") {
      return "/imaginganalytics";
    } else if (e === "edanalytics") {
      return "/edanalytics";
    } else if (e === "tune") {
      return "/tune";
    } else if (e === "leadermetrics") {
      return "/leadermetrics";
    } else {
      return "/";
    }
  };

  return (
    <div className="sidebar">
      {/* <div class="toplogo">
            <img src={LogoThree} alt="" className='mainlogo'/>
            <img src={LogoThreeSmall} alt="" className='samllogo'/>
            </div> */}
      <div className="sidenav">
        <ul>
          {/* <li><NavLink  className={({ isActive }) => (isActive ? 'active' : 'inactive')}  to={`/home`}><img style={{ height:'40px', width:'40px'}} src={Diconeone} alt="" /> <span>Home </span></NavLink></li> */}
          {get(userDatas, "application_accesses", [])
          ?.filter((inp) => inp.sidebar === 1)
          ?.map((val) => (
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "inactive")}
                to={urlFunction(get(val, "slug"))}
              >
                <img
                  src={get(val, "logo")}
                  style={{ height: "38px", width: "38px" }}
                  alt=""
                />
                <span>{get(val, "title")}</span>
              </NavLink>
            </li>
          ))}
          {/* <li><Link className="boxlink" to={`/realtime`}><img src={Diconetwo} alt="" /> <span>Real Time </span></Link></li>

                <li><img src={Diconethree} alt="" /> <span>Variance Management</span></li>
                <li><img src={Diconefour} alt="" /> <span>Biweekly Reporting</span></li>
                <li><img src={Diconefive} alt="" /> <span>Target Management</span></li>
                <li><img src={Diconesix} alt="" /> <span>Post</span></li>
                <li><img src={Diconeseven} alt="" /> <span>Active Daily Management</span></li>
                <li><img src={Diconeeight} alt="" /> <span>Position Management</span></li> */}
        </ul>
      </div>
    </div>
  );
};

export default MainSideNavbar;
